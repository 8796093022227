import React, { useEffect, useState, Fragment } from "react";
import { useNavigate, useLocation, Link } from "react-router-dom";
import { Row, Col, Card, CardBody } from "reactstrap";

import LoadingOverlay from "react-loading-overlay-ts";
import DNALoader from "../../../components/Loaders/DnaLoader";
import "../../../assets/custom css/loadoverlay.scss";

import GenFilter from "../../../components/Table Filters/GenFilter";
import DataTable from 'react-data-table-component';

import WarrantyDetails from '../../../components/Modals/WarrantyDetails';

const WarrantiesList = () => {

    const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [warranty, setwarranty] = useState([]);
  

  const location = useLocation();
  const { state } = location;
  console.log("Received Object:-", state);

  useEffect(() => {
    setData();
  }, []);

  function setData() {
    if (state) {
      console.log("Going to set data object for display")
      //   setLog(state)
      //   getuser(state.user_id);
      setwarranty(state.warrantyData)
    }
    else {
      setwarranty([])
    }
  }

  const TBcolumns = [
    {
      name: 'Model',
      selector: row => row.prod_model,
    },
    {
      name: 'Product Serial #',
      selector: row => row.prod_serial_no,
    },
    {
        name: 'Sellers Name',
        selector: row => row.seller_name,
      },
    {
      name: 'Date of Purchase',
      selector: row => new Date(row.purchase_date).toDateString().split(' ').slice(1).join(' '),
    },
    {
        name: 'Date of Installation',
        selector: row => new Date(row.install_date).toDateString().split(' ').slice(1).join(' '),
      },
    {
    name: 'Actions',
    selector:  (row) =>  {
      return (
        <WarrantyDetails data={row} />
      );
      }
    },
  ];

  const [filterText, setFilterText] = React.useState('');
  const [resetPaginationToggle, setResetPaginationToggle] = React.useState(false);
  const filteredItems = warranty.filter(
    item => item.prod_model && item.prod_model.toLowerCase().includes(filterText.toLowerCase()),
  );

  const subHeaderComponentMemo = React.useMemo(() => {
    const handleClear = () => {
    if (filterText) {
      setResetPaginationToggle(!resetPaginationToggle);
      setFilterText('');
    }
  };
  return (
    <GenFilter onFilter={e => setFilterText(e.target.value)} onClear={handleClear} filterText={filterText} />
  );
}, [filterText, resetPaginationToggle]);


  return (
    <Fragment>
        <div className="app-main__outer">
            <div className="app-main__inner">
                <div>
                    <div className="app-page-title">
                        <div className="page-title-wrapper">
                            <div className="page-title-heading fw-bold">
                                <div className="page-title-icon">
                                    <i className="pe-7s-credit icon-gradient bg-happy-green" />
                                </div>
                                <div>
                                    Warranties List
                                    <div className="page-title-subheading">
                                        View all warranties with basic details.
                                    </div>
                                </div>
                            </div>
                            <div className="page-title-actions">
                              <a href="/customer/register-product" className="AddData">
                                <button type="button" className="btn btn-custom-color btn-hover-shine me-3 btn">Add New</button>
                              </a>
                            </div>
                        </div>
                    </div>
                    <Row>
                        <Col md="12">
                            <Card className="main-card mb-3">
                                <CardBody>
                                <LoadingOverlay tag="div" active={loading}
                                    styles = {{overlay: (base) => ({...base})}}
                                    spinner = {<DNALoader/>}>
                                    <DataTable
                                        columns={TBcolumns}
                                        data={filteredItems}
                                        pagination
                                        paginationResetDefaultPage={resetPaginationToggle} // optionally, a hook to reset pagination to page 1
                                        subHeader
                                        subHeaderComponent={subHeaderComponentMemo}
                                        selectableRowDisabled={true}
                                        persistTableHead
                                    />
                                </LoadingOverlay>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </div>
            </div>
        </div>
    </Fragment>
  );

   
};
export default WarrantiesList;


// return (
//     <Fragment>
//         <div className="app-main__outer">
//             <div className="app-main__inner">
//                 <Form onSubmit={onSubmit}
//                     validate={validateFtn}
//                     render={({ handleSubmit, values, submitting, validating, valid }) => (
//                         <form onSubmit={handleSubmit}>
                            
//                             <div className="container">
//                                 <div className="row">
//                                     <div className="col-sm-12">
//                                     <h2 className="fs-2 my-4 fw-bold">Warranty List</h2>
                                    
//                                         <div className="mb-4 table-responsive">
//                                             <table className="table table-striped">
//                                                 <thead>
//                                                     <tr>
//                                                         <th scope="col">Model</th>
//                                                         <th scope="col">Product Serial#</th>
//                                                         <th scope="col">Sellers Name</th>
//                                                         <th scope="col">Date Of Purchase</th>
//                                                         <th scope="col">Date Of Installation</th>
//                                                     </tr>
//                                                 </thead>
//                                                 <tbody>
//                                                     <tr>
//                                                         <td>Mark</td>
//                                                         <td>456461</td>
//                                                         <td>Warnum</td>
//                                                         <td>25-03-2023</td>
//                                                         <td>25-03-2023</td>
//                                                     </tr>
//                                                     <tr>
//                                                         <td>Mark</td>
//                                                         <td>456461</td>
//                                                         <td>Warnum</td>
//                                                         <td>25-03-2023</td>
//                                                         <td>25-03-2023</td>
//                                                     </tr>
//                                                     <tr>
//                                                         <td>Mark</td>
//                                                         <td>456461</td>
//                                                         <td>Warnum</td>
//                                                         <td>25-03-2023</td>
//                                                         <td>25-03-2023</td>
//                                                     </tr>
//                                                     <tr>
//                                                         <td>Mark</td>
//                                                         <td>456461</td>
//                                                         <td>Warnum</td>
//                                                         <td>25-03-2023</td>
//                                                         <td>25-03-2023</td>
//                                                     </tr>
//                                                     <tr>
//                                                         <td>Mark</td>
//                                                         <td>456461</td>
//                                                         <td>Warnum</td>
//                                                         <td>25-03-2023</td>
//                                                         <td>25-03-2023</td>
//                                                     </tr>
//                                                     <tr>
//                                                         <td>Mark</td>
//                                                         <td>456461</td>
//                                                         <td>Warnum</td>
//                                                         <td>25-03-2023</td>
//                                                         <td>25-03-2023</td>
//                                                     </tr>
//                                                     <tr>
//                                                         <td>Mark</td>
//                                                         <td>456461</td>
//                                                         <td>Warnum</td>
//                                                         <td>25-03-2023</td>
//                                                         <td>25-03-2023</td>
//                                                     </tr>
//                                                     <tr>
//                                                         <td>Mark</td>
//                                                         <td>456461</td>
//                                                         <td>Warnum</td>
//                                                         <td>25-03-2023</td>
//                                                         <td>25-03-2023</td>
//                                                     </tr>
//                                                     <tr>
//                                                         <td>Mark</td>
//                                                         <td>456461</td>
//                                                         <td>Warnum</td>
//                                                         <td>25-03-2023</td>
//                                                         <td>25-03-2023</td>
//                                                     </tr>
//                                                 </tbody>
//                                             </table>
//                                         </div>
                                
//                                     </div>
                                    
//                                 </div>
//                             </div>
//                         </form>
//                     )}
//                 />
//             </div>
//         </div>

//     </Fragment>
// );