import React, { useEffect, useState, Fragment } from "react";
import { useTranslation } from 'react-i18next';
import { useNavigate, useLocation } from "react-router-dom";
import { Col, Row, Label, FormGroup, Input, Card, CardBody } from "reactstrap";
import { Form, Field } from "react-final-form";

import LoadingOverlay from "react-loading-overlay-ts";
import DNALoader from "../../components/Loaders/DnaLoader";
import "../../assets/custom css/loadoverlay.scss";
import Alerts from "../../components/Alerts/alerts";

import { SketchPicker } from "react-color";

import ConfigurationService from "./configuration.service";
import AuthService from "../Authentication/auth.service";


const TabGenralConfig = () => {

  // localStorage.setItem("buttons_color", '#3ac47d');

  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [B64converted, setB64converted] = useState("");
  const [displayColorPicker1, setdisplayColorPicker1] = useState(false);
  const [sketchPickerColor1, setSketchPickerColor1] = useState(localStorage.getItem("buttons_color"));
  const color1 = sketchPickerColor1;

  const [displayColorPicker2, setdisplayColorPicker2] = useState(false);
  const [sketchPickerColor2, setSketchPickerColor2] = useState(localStorage.getItem("topbar_color"));
  const color2 = sketchPickerColor2;

  const [displayColorPicker3, setdisplayColorPicker3] = useState(false);
  const [sketchPickerColor3, setSketchPickerColor3] = useState(localStorage.getItem("leftbar_color"));
  const color3 = sketchPickerColor3;

  const [displayColorPicker4, setdisplayColorPicker4] = useState(false);
  const [sketchPickerColor4, setSketchPickerColor4] = useState(localStorage.getItem("buttons_text_color"));
  const color4 = sketchPickerColor4;


  const [configs, setConfig] = useState([]);
  const { t, i18n } = useTranslation();

  useEffect(() => {
    i18n.changeLanguage(localStorage.getItem('language'));
    getSystemConfig();
  }, []);

  function getSystemConfig() {
    ConfigurationService.getConfiguration().then((response) => {        
      console.log("API Response:-", response);
      if(response.data.status){
        const [rec_data] = response.data.data;
        setConfig(rec_data);
        setColors(response.data.data[0].buttons_color, '--buttons-color');
        setColors(response.data.data[0].topbar_color, '--topbar-color');
        setColors(response.data.data[0].leftbar_color, '--leftbar-color');
        setColors(response.data.data[0].buttons_text_color, '--buttons-text-color');
        setSystemLogo(response.data.data[0].system_logo);
      }
      else {
        if(response.data.message === "Unauthorized") {
          AuthService.logout();
          navigate("/login");
          window.location.reload();
        }
        else {
          setColors('#3ac47d', 'onerror');
          alert(t("Unable to retreive configurations at the moment."))
        }
      }
    },
    (error) => {
      setColors('#3ac47d', 'onerror');
      alert(t("Unable to retreive configurations at the moment."))
    });
  }

  function setColors(color, param) {
    if(param === '--buttons-color') {
      localStorage.setItem("buttons_color", color);
      setSketchPickerColor1(color)
      document.documentElement.style.setProperty('--buttons-color', color)
    }
    if(param === '--topbar-color') {
      localStorage.setItem("topbar_color", color);
      setSketchPickerColor2(color)
      document.documentElement.style.setProperty('--topbar-color', color)
    }
    if(param === '--leftbar-color') {
      localStorage.setItem("leftbar_color", color);
      setSketchPickerColor3(color)
      document.documentElement.style.setProperty('--leftbar-color', color)
    }
    if(param === '--buttons-text-color') {
      localStorage.setItem("buttons_text_color", color);
      setSketchPickerColor4(color)
      document.documentElement.style.setProperty('--buttons-text-color', color)
    }
    if(param === 'onerror') {
      localStorage.setItem("buttons_color", color);
      localStorage.setItem("topbar_color", '#fff');
      localStorage.setItem("leftbar_color", '#fff');
      localStorage.setItem("buttons_text_color", '#fff');
      document.documentElement.style.setProperty('--buttons-color', color)
      document.documentElement.style.setProperty('--topbar-color', '#fff')
      document.documentElement.style.setProperty('--leftbar-color', '#fff')
      document.documentElement.style.setProperty('--buttons-text-color', '#fff')
    }
  }

  function setSystemLogo(sys_logo) {
    localStorage.setItem("system_logo", sys_logo);
  }

  const onChangelogo = e => {
    const files = e.target.files;
    const file = files[0];
    getBase64(file).then(result => {
      const convertedlogo = result;
      setB64converted(convertedlogo)
      console.log("Final Converted:-", convertedlogo)
    });
  }

  function getBase64(file) {
    return new Promise(resolve => {
      let fileInfo;
      let baseURL = "";
      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        console.log("Called", reader);
        baseURL = reader.result;
        console.log(baseURL);
        resolve(baseURL);
      };
    });
  };

  function handleSave(values) {
    console.log("Form Values:-", values);
    localStorage.setItem("buttons_color", sketchPickerColor1);
    document.documentElement.style.setProperty('--buttons-color', sketchPickerColor1);
    localStorage.setItem("topbar_color", sketchPickerColor2);
    document.documentElement.style.setProperty('--topbar-color', sketchPickerColor2);
    localStorage.setItem("leftbar_color", sketchPickerColor3);
    document.documentElement.style.setProperty('--leftbar-color', sketchPickerColor3);
    localStorage.setItem("buttons_text_color", sketchPickerColor4);
    document.documentElement.style.setProperty('--buttons-text-color', sketchPickerColor4);
    var payloadData = {
      functionName: "SetConfigurations",
      postData: {
        name: values.name,
        email: values.email,
        buttons_color: sketchPickerColor1,
        topbar_color: sketchPickerColor2,
        leftbar_color: sketchPickerColor3,
        buttons_text_color: sketchPickerColor4,
        // system_logo: B64converted
      },
    }
    if(B64converted) {
      payloadData.postData['system_logo']= B64converted;
    }
    ConfigurationService.setConfiguration(payloadData).then((response) => {        
      console.log("API Response:-", response);
      setLoading(false);
      if(response.data.status){
        if(response.data.message !== '') {
          Alerts.swalSuccessAlert(response.data.message)
        }
        else {
          Alerts.swalSuccessAlert(t("Configuration updated successfully."));
        }
        getSystemConfig();
        setTimeout(() => {
          window.location.reload();
        }, 2000);
      }
      else {
        if(response.data.message !== '') {
          if(response.data.message === "Unauthorized") {
            AuthService.logout();
            navigate("/login");
            window.location.reload();
          }
          else {
            Alerts.swalErrorAlert(response.data.message);
          }
        }
        else {
          Alerts.swalErrorAlert(t("Error: Can't update configuration at the moment. Please try again later."))
        }
      }
    },
    (error) => {
      setLoading(false);
      const resMessage = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
      console.log("Error:-", error)
      Alerts.swalErrorAlert(t("Network Operation failed."))
    });
  }

  const validateFtn = (values) => {
    const errors = {};
    function validateEmail(email) {
      var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(String(email).toLowerCase());
    }
    if (!values.name) {
      errors.name = t("System Name Required.");
    }
    if (!values.email) {
      errors.email = t("Email Required.");
    }
      else if (!validateEmail(values.email)) {
      errors.email = t("Please enter a valid email address.");
    }
    return errors;
  };

  const colorPicker = (val) => {
    if(val === '1') {
      setdisplayColorPicker1(true);
    }
    if(val === '2') {
      setdisplayColorPicker2(true);
    }
    if(val === '3') {
      setdisplayColorPicker3(true);
    }
    if(val === '4') {
      setdisplayColorPicker4(true);
    }
  };

  const handleClose = (val) => {
    if(val === '1') {
      setdisplayColorPicker1(false);
    }
    if(val === '2') {
      setdisplayColorPicker2(false);
    }
    if(val === '3') {
      setdisplayColorPicker3(false);
    }
    if(val === '4') {
      setdisplayColorPicker4(false);
    }
  };

  const popover = {
    position: "absolute",
    zIndex: "2",
  };
  const cover = {
    position: "fixed",
    top: "0px",
    right: "0px",
    bottom: "0px",
    left: "0px",
  };

  return (
    <Fragment>
      <Card className="main-card mb-3">
        <CardBody className="col-md-8">
          <Form
            initialValues={configs}
            validate={validateFtn}
            onSubmit={handleSave}
            FormState
            render={({ handleSubmit, values, submitting, validating, valid, form: { getState } }) => (
              <form onSubmit={handleSubmit}>
                <LoadingOverlay tag="div" active={loading}
                  styles={{ overlay: (base) => ({ ...base }) }}
                  spinner={<DNALoader />}>
                  <Row form>
                    <Col md={12}>
                      <FormGroup>
                        <Field name="name">
                          {({ input, meta }) => (
                            <div>
                              <div className="row mb-3">
                                <Label for="name" className="col-sm-2 col-form-label">{t('System Name')}</Label>
                                <div className="col-sm-10">
                                  <Input {...input} type="text"
                                    invalid={meta.error && meta.touched}
                                    placeholder={t('System Name')}
                                  />
                                  {meta.error && meta.touched &&
                                    <span className="text-danger">{meta.error}</span>}
                                </div>
                              </div>
                            </div>
                          )}
                        </Field>
                      </FormGroup>
                      <FormGroup>
                        <Field name="email">
                          {({ input, meta }) => (
                            <div>
                              <div className="row mb-3">
                                <Label for="email" className="col-sm-2 col-form-label">{t('Admin Email')}</Label>
                                <div className="col-sm-10">
                                  <Input {...input} type="email"
                                    invalid={meta.error && meta.touched}
                                    placeholder={t('Admin Email')}
                                  />
                                  {meta.error && meta.touched &&
                                    <span className="text-danger">{meta.error}</span>}
                                </div>
                              </div>
                            </div>
                          )}
                        </Field>
                      </FormGroup>
                      <div className="row">
                        <Label for="name" className="col-sm-2 col-form-label">{t('Theme Colors')}</Label>
                        <div className="col-md-10">
                          <div className="row">
                            <div className="col-md-6">
                              <FormGroup>
                                <div>
                                  <div className="row mb-3">
                                    <Label for="name" className="col-sm-6 col-form-label">{t('Buttons-Background')}</Label>
                                    <div className="col-sm-3">
                                      <button className="btn btn-lg btn-outline-2x btn-outline-secondary me-3 mt-2"
                                        type="button"
                                        style={{ backgroundColor: color1 }}
                                        onClick={() => colorPicker('1')}>
                                      </button>
                                      {displayColorPicker1 ? (
                                        <div style={popover}>
                                          <div style={cover} onClick={() => handleClose('1')} />
                                          <SketchPicker className="mx-auto mb-4"
                                            onChange={(color) => {
                                              setSketchPickerColor1(color.hex);
                                            }}
                                            color={sketchPickerColor1}
                                          />
                                        </div>
                                      ) : null}
                                    </div>
                                  </div>
                                </div>
                              </FormGroup>
                            </div>
                            <div className="col-md-6">
                              <FormGroup>
                                <div>
                                  <div className="row mb-3">
                                    <Label for="name" className="col-sm-5 col-form-label">{t('Buttons Text')}</Label>
                                    <div className="col-sm-3">
                                      <button className="btn btn-lg btn-outline-2x btn-outline-secondary me-3 mt-2"
                                        type="button"
                                        style={{ backgroundColor: color4 }}
                                        onClick={() => colorPicker('4')}>
                                      </button>
                                      {displayColorPicker4 ? (
                                        <div style={popover}>
                                          <div style={cover} onClick={() => handleClose('4')} />
                                          <SketchPicker className="mx-auto mb-4"
                                            onChange={(color) => {
                                              setSketchPickerColor4(color.hex);
                                            }}
                                            color={sketchPickerColor4}
                                          />
                                        </div>
                                      ) : null}
                                    </div>
                                  </div>
                                </div>
                              </FormGroup>
                            </div>

                            <div className="col-md-6">
                              <FormGroup >
                                <div>
                                  <div className="row mb-3">
                                    <Label for="name" className="col-sm-6 col-form-label">{t('Top Bar')}</Label>
                                    <div className="col-sm-3">
                                      <button className="btn btn-lg btn-outline-2x btn-outline-secondary me-3 mt-2"
                                        type="button"
                                        style={{ backgroundColor: color2 }}
                                        onClick={() => colorPicker('2')}>
                                      </button>
                                      {displayColorPicker2 ? (
                                        <div style={popover}>
                                          <div style={cover} onClick={() => handleClose('2')} />
                                          <SketchPicker className="mx-auto mb-4"
                                            onChange={(color) => {
                                              setSketchPickerColor2(color.hex);
                                            }}
                                            color={sketchPickerColor2}
                                          />
                                        </div>
                                      ) : null}
                                    </div>
                                  </div>
                                </div>
                              </FormGroup>
                            </div>
                            <div className="col-md-6">
                              <FormGroup>
                                <div>
                                  <div className="row mb-3">
                                    <Label for="name" className="col-sm-5 col-form-label">{t('Left Bar')}</Label>
                                    <div className="col-sm-3">
                                      <button className="btn btn-lg btn-outline-2x btn-outline-secondary me-3 mt-2"
                                        type="button"
                                        style={{ backgroundColor: color3 }}
                                        onClick={() => colorPicker('3')}>
                                      </button>
                                      {displayColorPicker3 ? (
                                        <div style={popover}>
                                          <div style={cover} onClick={() => handleClose('3')} />
                                          <SketchPicker className="mx-auto mb-4"
                                            onChange={(color) => {
                                              setSketchPickerColor3(color.hex);
                                            }}
                                            color={sketchPickerColor3}
                                          />
                                        </div>
                                      ) : null}
                                    </div>
                                  </div>
                                </div>
                              </FormGroup>

                            </div>
                          </div>
                        </div>
                      </div>

                      <FormGroup>
                        <Field name="file">
                          {({ input, meta }) => (
                            <div className="row mb-3">
                              <Label for="file" className="col-sm-2 col-form-label">{t('Upload Logo')}</Label>
                              <div className="col-sm-10">
                                <Input {...input} type="file" value={values.file} onChange={onChangelogo} />
                              </div>
                            </div>
                          )}
                        </Field>
                      </FormGroup>
                      <FormGroup>
                        <div className="d-flex justify-content-center EditData">
                          <button type="button" onClick={() => navigate(-1)} className="btn btn-outline-light btn-hover-shine me-3 mt-3">{t('Cancel')}</button>
                          <button className="btn btn-custom-color btn-hover-shine me-3 mt-3" type="submit" disabled={!valid}>{t('Save')}</button>
                        </div>
                      </FormGroup>
                    </Col>
                  </Row>
                </LoadingOverlay>
              </form>

            )}

          />

        </CardBody>
      </Card>
    </Fragment>
  );

};
export default TabGenralConfig; 