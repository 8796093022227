import React, { useState, Fragment } from "react";
import { Col, Row, Button, Label, FormGroup, Input } from "reactstrap";
import { Form, Field } from "react-final-form";
import { useNavigate } from "react-router-dom";

import LoadingOverlay from "react-loading-overlay-ts";
import DNALoader from "../../../components/Loaders/DnaLoader";
import "../../../assets/custom css/loadoverlay.scss";
import Alerts from "../../../components/Alerts/alerts";

import AuthcustService from "./authcust.service";


const CustomerRegistration = () => {

    const navigate = useNavigate();

    localStorage.removeItem("register");
    const [loading, setLoading] = useState(false);
    const [logo, setLogo] = useState(localStorage.getItem("system_logo"));

    const onSubmit = values => {
        console.log(values);
        setLoading(true);
        AuthcustService.register(values).then((response) => {
            console.log("Registration API Resp:-", response);
            setLoading(false);
            if(response.data.status){
                if(response.data.message !== '') {
                    Alerts.swalSuccessAlert(response.data.message)
                }
                else {
                    Alerts.swalSuccessAlert("Registered successfully.");
                }
                navigate("/customer/login");
                window.location.reload();
            }
            else {
                if(response.data.message !== '') {
                    Alerts.swalErrorAlert(response.data.message)
                }
                else {
                    Alerts.swalErrorAlert("Error: Can't do the registration at the moment. Please try again later.")
                }
            }
        },
        (error) => {
            setLoading(false);
            const resMessage = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
            console.log("Error:-", error)
            Alerts.swalErrorAlert("Network Operation failed.")
        });
    };

    
    const validateFtn = (values) => {
        const errors = {};
        function validateEmail(email) {
        var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
        }
        if (!values.email) {
        errors.email = "Email Required.";
        }
        else if (!validateEmail(values.email)) {
        errors.email = "Please enter a valid email address.";
        }
        if (!values.password) {
            errors.password = "Password Required.";
        }
        if (!values.salutation) {
        errors.salutation = "Salutation Required.";
        }
        if (!values.first_name) {
            errors.first_name = "First/Given Name Required.";
        }
        if (!values.last_name) {
            errors.last_name = "Last/Family Name Required.";
        }
        if (!values.birth_date) {
            errors.birth_date = "Birth Date Required.";
        }
        if (!values.mar_status) {
            errors.mar_status = "Marital Status Required.";
        }
        if (!values.house_no) {
            errors.house_no = "This field is required.";
        }
        if (!values.build_street) {
            errors.build_street = "This field is required.";
        }
        if (!values.state) {
            errors.state = "State Required.";
        }
        if (!values.city) {
            errors.city = "City Required.";
        }
        if (!values.zip_code) {
            errors.zip_code = "ZIP Code Required.";
        }
        if (!values.country) {
            errors.country = "Country Required.";
        }
        if (!values.phone) {
            errors.phone = "Phone Required.";
        }
        return errors;
    };

    return (
        <Fragment>
            <Form onSubmit={onSubmit}
                validate={validateFtn}
                render={({ handleSubmit, values, submitting, validating, valid }) => (
                    <form onSubmit={handleSubmit}>
                        <LoadingOverlay tag="div" active={loading}
                            styles = {{overlay: (base) => ({...base})}}
                            spinner = {<DNALoader/>}>
                            <div className="container">
                            <div className="row">
                                <div className="col-md-12 border-bottom">

                                    <div className="d-flex justify-content-between align-items-center mt-5">
                                        {/* <span className="app-logo"></span> */}
                                        <img src={logo} className="app-logo" />
                                        <select className="bg-transparent border-0">
                                            <option selected>EN</option>
                                            <option>FR</option>
                                        </select>
                                    </div>

                                    <nav class="navbar navbar-expand-lg navbar-light bg-transparent my-4">
                                        <div class="container-fluid px-0">
                                            <div class="collapse navbar-collapse">
                                                <ul class="navbar-nav me-auto mb-2 text-black fs-5 mb-lg-0">
                                                    <li class="nav-item">
                                                        <a class="nav-link ps-0" href="void"><span class="navbar-toggler-icon"></span></a>
                                                    </li>
                                                    <li class="nav-item">
                                                        <a class="nav-link" href="void">Product</a>
                                                    </li>
                                                    <li class="nav-item">
                                                        <a class="nav-link" href="void">Services</a>
                                                    </li>
                                                    <li class="nav-item">
                                                        <a class="nav-link" href="void">Contact Us</a>
                                                    </li>
                                                </ul>
                                                <div class="d-flex">
                                                    <h3 className="fw-bold">Bosch Smart Lock</h3>
                                                </div>
                                            </div>
                                        </div>
                                    </nav>

                                </div>
                            </div>
                                <div className="row">
                                    <div className="col-sm-12">
                                    <h2 className="fs-2 my-4 fw-bold">Customer Registration</h2>
                                    
                                    <h4 className="fs-3 my-3"> Contact information</h4>

                                    <Row form className="row">
                                        <Col md={6}>
                                            <FormGroup>
                                                <Field name="salutation">
                                                    {({ input, meta }) => (
                                                        <div className="mb-4">
                                                            <Label className="mb-2">Salutation *</Label>
                                                            <Input {...input} type="select" 
                                                                invalid={meta.error && meta.touched} 
                                                            >
                                                                <option disabled={true} value="">--Choose an option--</option>
                                                                <option value="Mr.">Mr.</option>
                                                                <option value="Mrs.">Mrs.</option>
                                                                <option value="Miss.">Miss.</option>
                                                            </Input>
                                                            {meta.error && meta.touched && 
                                                                <span className="text-danger">{meta.error}</span>}
                                                        </div>
                                                    )}
                                                </Field>
                                            </FormGroup>
                                        </Col>
                                        <Col md={6}>
                                            <FormGroup>
                                                <Field name="first_name">
                                                    {({ input, meta }) => (
                                                        <div className="mb-4">
                                                            <Label className="mb-2">First/Given Name *</Label>
                                                            <Input  {...input} type="text" className="form-control" placeholder="" />
                                                            {meta.error && meta.touched && 
                                                            <span className="text-danger">{meta.error}</span>}
                                                        </div>
                                                    )}
                                                </Field>
                                            </FormGroup>
                                        </Col>
                                        <Col md={6}>
                                            <FormGroup>
                                                <Field name="last_name">
                                                    {({ input, meta }) => (
                                                        <div className="mb-4">
                                                            <Label className="mb-2">Last/Family Name *</Label>
                                                            <Input  {...input} type="text" className="form-control" placeholder="" />
                                                            {meta.error && meta.touched && 
                                                            <span className="text-danger">{meta.error}</span>}
                                                        </div>
                                                    )}
                                                </Field>
                                            </FormGroup>
                                        </Col>
                                        <Col md={6}>
                                            <FormGroup>
                                                <Field name="birth_date">
                                                    {({ input, meta }) => (
                                                        <div className="mb-4">
                                                            <Label className="mb-2">Birth Date *</Label>
                                                            <Input {...input} type="date" className="form-control" placeholder="" />
                                                            {meta.error && meta.touched && 
                                                            <span className="text-danger">{meta.error}</span>}
                                                        </div>
                                                    )}
                                                </Field>
                                            </FormGroup>
                                        </Col>
                                        <Col md={6}>
                                            <FormGroup>
                                                <Field name="mar_status">
                                                    {({ input, meta }) => (
                                                        <div className="mb-4">
                                                            <Label className="mb-2">Marital Status</Label>
                                                            <Input {...input} type="text" className="form-control" placeholder="" />
                                                            {meta.error && meta.touched && 
                                                            <span className="text-danger">{meta.error}</span>}
                                                        </div>
                                                    )}
                                                </Field>
                                            </FormGroup>
                                        </Col>
                                        <Col md={6}>
                                            <FormGroup>
                                                <Field name="house_no">
                                                    {({ input, meta }) => (
                                                        <div className="mb-4">
                                                            <Label className="mb-2">House No. / Lot No. / Floor / Block No. </Label>
                                                            <Input {...input} type="text" className="form-control" placeholder="" />
                                                            {meta.error && meta.touched && 
                                                            <span className="text-danger">{meta.error}</span>}
                                                        </div>
                                                    )}
                                                </Field>
                                            </FormGroup>
                                        </Col>
                                        <Col md={6}>
                                            <FormGroup>
                                                <Field name="build_street">
                                                    {({ input, meta }) => (
                                                        <div className="mb-4">
                                                            <Label className="mb-2">Building Name/Street Adress *</Label>
                                                            <Input {...input} type="text" className="form-control" placeholder="" />
                                                            {meta.error && meta.touched && 
                                                            <span className="text-danger">{meta.error}</span>}
                                                        </div>
                                                    )}
                                                </Field>
                                            </FormGroup>
                                        </Col>
                                        <Col md={6}>
                                            <FormGroup>
                                                <Field name="state">
                                                    {({ input, meta }) => (
                                                        <div className="mb-4">
                                                            <Label className="mb-2">State *</Label>
                                                            <Input {...input} type="select" className="form-control" placeholder=""
                                                                invalid={meta.error && meta.touched} 
                                                            >
                                                                <option disabled={true} value="">--Choose an option--</option>
                                                                <option value="1">Option 1</option>
                                                                <option value="2">Option 2</option>
                                                                <option value="3">Option 3</option>
                                                            </Input>
                                                            {meta.error && meta.touched && 
                                                            <span className="text-danger">{meta.error}</span>}
                                                        </div>
                                                    )}
                                                </Field>
                                            </FormGroup>
                                        </Col>
                                        <Col md={6}>
                                            <FormGroup>
                                                <Field name="city">
                                                    {({ input, meta }) => (
                                                        <div className="mb-4">
                                                            <Label className="mb-2">City *</Label>
                                                            <Input {...input} type="select" className="form-control" placeholder="" 
                                                                invalid={meta.error && meta.touched} 
                                                            >
                                                                <option disabled={true} value="">--Choose an option--</option>
                                                                <option value="1">Option 1</option>
                                                                <option value="2">Option 2</option>
                                                                <option value="3">Option 3</option>
                                                            </Input>
                                                            {meta.error && meta.touched && 
                                                            <span className="text-danger">{meta.error}</span>}
                                                        </div>
                                                    )}
                                                </Field>
                                            </FormGroup>
                                        </Col>
                                        <Col md={6}>
                                            <FormGroup>
                                                <Field name="zip_code">
                                                    {({ input, meta }) => (
                                                        <div className="mb-4">
                                                            <Label className="mb-2">Zip Code *</Label>
                                                            <Input {...input} type="text" className="form-control" placeholder="" />
                                                            {meta.error && meta.touched && 
                                                            <span className="text-danger">{meta.error}</span>}
                                                        </div>
                                                    )}
                                                </Field>
                                            </FormGroup>
                                        </Col>
                                        <Col md={6}>
                                            <FormGroup>
                                                <Field name="country">
                                                    {({ input, meta }) => (
                                                        <div className="mb-4">
                                                            <Label className="mb-2">Country *</Label>
                                                            <Input {...input} type="select" className="form-control" placeholder="" 
                                                                invalid={meta.error && meta.touched} 
                                                            >
                                                                <option disabled={true} value="">--Choose an option--</option>
                                                                <option value="1">Option 1</option>
                                                                <option value="2">Option 2</option>
                                                                <option value="3">Option 3</option>
                                                            </Input>
                                                            {meta.error && meta.touched && 
                                                            <span className="text-danger">{meta.error}</span>}
                                                        </div>
                                                    )}
                                                </Field>
                                            </FormGroup>
                                        </Col>
                                        <Col md={6}>
                                            <FormGroup>
                                                <Field name="phone">
                                                    {({ input, meta }) => (
                                                        <div className="mb-4">
                                                            <Label className="mb-2">Phone Number *</Label>
                                                            <Input {...input} type="text" className="form-control" placeholder="" />
                                                            {meta.error && meta.touched && 
                                                            <span className="text-danger">{meta.error}</span>}
                                                        </div>
                                                    )}
                                                </Field>
                                            </FormGroup>
                                        </Col>
                                        <Col md={6}>
                                            <FormGroup>
                                                <Field name="email">
                                                    {({ input, meta }) => (
                                                        <div className="mb-4">
                                                            <Label className="mb-2">Email Address *</Label>
                                                            <Input {...input} type="text" className="form-control" placeholder="" />
                                                            {meta.error && meta.touched && 
                                                            <span className="text-danger">{meta.error}</span>}
                                                        </div>
                                                    )}
                                                </Field>
                                            </FormGroup>
                                        </Col>
                                        <Col md={6}>
                                            <FormGroup>
                                                <Field name="password">
                                                    {({ input, meta }) => (
                                                        <div className="mb-4">
                                                            <Label className="mb-2">Password *</Label>
                                                            <Input {...input} type="password" className="form-control" placeholder="********" />
                                                            {meta.error && meta.touched && 
                                                            <span className="text-danger">{meta.error}</span>}
                                                        </div>
                                                    )}
                                                </Field>
                                            </FormGroup>
                                        </Col>
                                        <Col md={12}>
                                            <FormGroup>
                                                <div className="d-flex justify-content-center mb-5 mt-3">
                                                    <Button color="primary" className="me-3" type="submit" disabled={!valid}>
                                                        Submit
                                                    </Button>
                                                    <Button color="light" size="lg">
                                                        Reset
                                                    </Button>
                                                </div>
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    </div>
                                </div>

                                
                            </div>
                            <footer class="container">
                                <div className="pt-5 border-top">
                                <div class="row">
                                    <div class="col-md-3">
                                        <h5 className="fw-bold mb-4">Bosch Malaysia</h5>
                                        <ul class="list-unstyled">
                                            <li className="my-2"><a href="void" className="text-dark">About Bosch Malaysia</a></li>
                                            <li className="my-2"><a href="void" className="text-dark">Corporate Info</a></li>
                                            
                                        </ul>
                                    </div>
                                    <div class="col-md-3">
                                    <h5 className="fw-bold mb-4">Service</h5>
                                        <ul class="list-unstyled">
                                        <li className="my-2"><a href="void" className="text-dark">Product Warranty</a></li>
                                        <li className="my-2"><a href="void" className="text-dark">FAQ</a></li>
                                        <li className="my-2"><a href="void" className="text-dark">Download Center</a></li>
                                        </ul>
                                    </div>
                                    <div class="col-md-3">
                                    <h5 className="fw-bold mb-4">Contact Us</h5>
                                        <ul class="list-unstyled">
                                        <li className="my-2"><a href="void" className="text-dark">Contact Form</a></li>
                                        </ul>
                                    </div>
                                    <div class="col-md-3 text-end">                                        
                                            <ul class="list-inline">
                                                <li class="list-inline-item me-4"><a href="void" className="text-dark"><svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" class="bi bi-facebook" viewBox="0 0 16 16">
                                                    <path d="M16 8.049c0-4.446-3.582-8.05-8-8.05C3.58 0-.002 3.603-.002 8.05c0 4.017 2.926 7.347 6.75 7.951v-5.625h-2.03V8.05H6.75V6.275c0-2.017 1.195-3.131 3.022-3.131.876 0 1.791.157 1.791.157v1.98h-1.009c-.993 0-1.303.621-1.303 1.258v1.51h2.218l-.354 2.326H9.25V16c3.824-.604 6.75-3.934 6.75-7.951z" />
                                                </svg></a></li>
                                                <li class="list-inline-item me-4"><a href="void" className="text-dark"><svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" class="bi bi-instagram" viewBox="0 0 16 16">
                                                    <path d="M8 0C5.829 0 5.556.01 4.703.048 3.85.088 3.269.222 2.76.42a3.917 3.917 0 0 0-1.417.923A3.927 3.927 0 0 0 .42 2.76C.222 3.268.087 3.85.048 4.7.01 5.555 0 5.827 0 8.001c0 2.172.01 2.444.048 3.297.04.852.174 1.433.372 1.942.205.526.478.972.923 1.417.444.445.89.719 1.416.923.51.198 1.09.333 1.942.372C5.555 15.99 5.827 16 8 16s2.444-.01 3.298-.048c.851-.04 1.434-.174 1.943-.372a3.916 3.916 0 0 0 1.416-.923c.445-.445.718-.891.923-1.417.197-.509.332-1.09.372-1.942C15.99 10.445 16 10.173 16 8s-.01-2.445-.048-3.299c-.04-.851-.175-1.433-.372-1.941a3.926 3.926 0 0 0-.923-1.417A3.911 3.911 0 0 0 13.24.42c-.51-.198-1.092-.333-1.943-.372C10.443.01 10.172 0 7.998 0h.003zm-.717 1.442h.718c2.136 0 2.389.007 3.232.046.78.035 1.204.166 1.486.275.373.145.64.319.92.599.28.28.453.546.598.92.11.281.24.705.275 1.485.039.843.047 1.096.047 3.231s-.008 2.389-.047 3.232c-.035.78-.166 1.203-.275 1.485a2.47 2.47 0 0 1-.599.919c-.28.28-.546.453-.92.598-.28.11-.704.24-1.485.276-.843.038-1.096.047-3.232.047s-2.39-.009-3.233-.047c-.78-.036-1.203-.166-1.485-.276a2.478 2.478 0 0 1-.92-.598 2.48 2.48 0 0 1-.6-.92c-.109-.281-.24-.705-.275-1.485-.038-.843-.046-1.096-.046-3.233 0-2.136.008-2.388.046-3.231.036-.78.166-1.204.276-1.486.145-.373.319-.64.599-.92.28-.28.546-.453.92-.598.282-.11.705-.24 1.485-.276.738-.034 1.024-.044 2.515-.045v.002zm4.988 1.328a.96.96 0 1 0 0 1.92.96.96 0 0 0 0-1.92zm-4.27 1.122a4.109 4.109 0 1 0 0 8.217 4.109 4.109 0 0 0 0-8.217zm0 1.441a2.667 2.667 0 1 1 0 5.334 2.667 2.667 0 0 1 0-5.334z" />
                                                </svg></a></li>
                                                <li class="list-inline-item"><a href="void" className="text-dark"><svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" class="bi bi-youtube" viewBox="0 0 16 16">
                                                    <path d="M8.051 1.999h.089c.822.003 4.987.033 6.11.335a2.01 2.01 0 0 1 1.415 1.42c.101.38.172.883.22 1.402l.01.104.022.26.008.104c.065.914.073 1.77.074 1.957v.075c-.001.194-.01 1.108-.082 2.06l-.008.105-.009.104c-.05.572-.124 1.14-.235 1.558a2.007 2.007 0 0 1-1.415 1.42c-1.16.312-5.569.334-6.18.335h-.142c-.309 0-1.587-.006-2.927-.052l-.17-.006-.087-.004-.171-.007-.171-.007c-1.11-.049-2.167-.128-2.654-.26a2.007 2.007 0 0 1-1.415-1.419c-.111-.417-.185-.986-.235-1.558L.09 9.82l-.008-.104A31.4 31.4 0 0 1 0 7.68v-.123c.002-.215.01-.958.064-1.778l.007-.103.003-.052.008-.104.022-.26.01-.104c.048-.519.119-1.023.22-1.402a2.007 2.007 0 0 1 1.415-1.42c.487-.13 1.544-.21 2.654-.26l.17-.007.172-.006.086-.003.171-.007A99.788 99.788 0 0 1 7.858 2h.193zM6.4 5.209v4.818l4.157-2.408L6.4 5.209z" />
                                                </svg></a></li>
                                            </ul>
                                    </div>
                                </div>

                                    <div className="row border-top border-dark mt-2">
                                        <div className="col-sm-6 col-md-5 col-lg-4">
                                            <p className="pt-3">Copyright @ 2023 Robert Bosch Sdn.Bhd</p>                   
                                        </div>   
                                        <div className="col-sm-6 col-md-7 col-lg-8">
                                            <ul class="list-inline pt-3">
                                                <li className="list-inline-item me-3"><a href="void" className="text-dark">Privacy Setting</a></li>
                                                <li className="list-inline-item me-3"><a href="void" className="text-dark">Legal Notice</a></li>
                                                <li className="list-inline-item me-3"><a href="void" className="text-dark">Data Protection Policy</a></li>
                                            </ul>
                                        </div>                   
                                                
                                    </div>
                                </div>
                            
                            </footer>
                        </LoadingOverlay>
                    </form>
                )}
            />

        </Fragment>
    );

}
export default CustomerRegistration;