import React, { useState, useRef, useEffect } from "react";
import { useTranslation } from 'react-i18next';
import { useNavigate } from "react-router-dom";

import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import CheckButton from "react-validation/build/button";

import {Card, CardBody, CardTitle } from "reactstrap";
import LoadingOverlay from "react-loading-overlay-ts";
import DNALoader from "../../components/Loaders/DnaLoader";
import "../../assets/custom css/loadoverlay.scss";

import Alerts from "../../components/Alerts/alerts";

import RoleService from "./roles.service";
import CommonService from "../common.service";
import AuthService from "../Authentication/auth.service";
import $ from 'jquery'; 


$('body').on("click",".toggle-btn",function(){
  
  var element = document.getElementById($(this).attr('aria-controls'));
  if(element.classList.contains("show")){
    const myTimeout = setTimeout(function(){
      element.classList.remove("show");
    }, 50);
    
  }else{
    const myTimeout = setTimeout(function(){
      element.classList.add("show");
    }, 50);
  }
  
})
const AddRole = () => {

  const API_URL  = process.env.REACT_APP_BASEURL;
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const [modulesList, setModules] = useState("");
  const [functionsList, setFunctions] = useState("");
  const [loading, setLoading] = useState(false);

  const form = useRef();
  const checkBtn = useRef();

  const [rolename, setRolename] = useState("");
  const [landing, setLanding] = useState("");
  const [functionvalues, setFunctvalues] = useState([]);
  const [modulesvalues, setModvalues] = useState([]);
 
  const [successful, setSuccessful] = useState(false);
  const [message, setMessage] = useState("");


  useEffect(() => {
    i18n.changeLanguage(localStorage.getItem('language'));
    fetchModules();
    fetchFunctions();
  }, []);

  const required = (value) => {
    if (!value) {
      return (
        <div className="invalid-feedback d-block">
          {t('This field is required!')}
        </div>
      );
    }
  };
  
  const vrolename = (value) => {
    if (value.length < 3 || value.length > 20) {
      return (
        <div className="invalid-feedback d-block">
          {t('The rolename must be between 3 and 20 characters.')}
        </div>
      );
    }
  };

  function fetchModules() {
    var pdata = {
      status:1
    }
    CommonService.fetchModuleswithfunctions(pdata).then((response) => {
      console.log("Get Modules response:-", response)
      if(response.data.message === "Unauthorized") {
        AuthService.logout();
        navigate("/login");
        window.location.reload();
      }
      else {
        setModules(response.data.data);
      }
    },
    (error) => {
      const errContent =
      (error.response && error.response.data) || error.message || error.toString();
      if(errContent) {
        Alerts.swalErrorAlert(errContent)
      }
      else {
        Alerts.swalErrorAlert(t("Server Error, Please try again later."))
      }
    })
  };

  function fetchFunctions() {
    CommonService.fetchFunctions().then((response) => {
      console.log("Get Functions response:-", response.data.data)
      if(response.data.message === "Unauthorized") {
        AuthService.logout();
        navigate("/login");
        window.location.reload();
      }
      else {
        setFunctions(response.data.data);
      }
    },
    (error) => {
      const errContent =
      (error.response && error.response.data) || error.message || error.toString();
      if(errContent) {
        Alerts.swalErrorAlert(errContent)
      }
      else {
        Alerts.swalErrorAlert(t("Server Error, Please try again later."))
      }
    })
  };

  const onChangeRolename = (e) => {
    const rolename = e.target.value;
    setRolename(rolename);
  };

  const onChangelanding = (e) => {
    const land = e.target.value;
    setLanding(land)
  }

  const handleFunctionChange = (e) => {
    // const funcval = e.target.value;
    // console.log("functionvalues")
    // console.log(funcval)
    // functionvalues.push(funcval);
    
    if(e.target.checked){
      functionvalues.push(e.target.value);
    }
    else {
      var darr = functionvalues;
      const index = darr.indexOf(e.target.value);
      if (index > -1) { // only splice array when item is found
        darr.splice(index, 1); // 2nd parameter means remove one item only
      }
      setFunctvalues(darr);
    }
  };

  const handleModuleChange = (e) => {
    const modval = e.target.value;
    if($(e.target).prop( "checked" ))
    $(".child-check-box-"+modval).prop( "checked", true );
    else
    $(".child-check-box-"+modval).prop( "checked", false );
    modulesvalues.push(modval);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setMessage("");
    setSuccessful(false);
    form.current.validateAll();
    var Modules = [];
    var Functions = [];
    $('input.module-checkbox:checkbox:checked').each(function () {

      var sThisVal = $(this).val();
      var arr = {};
      arr['module_id'] = sThisVal;
      $('input.child-check-box-'+sThisVal).each(function () {
        if($(this).prop( "checked" )){
          arr[$(this).attr('data-label')] = 1;
          Functions.push($(this).val());
        }else{
          arr[$(this).attr('data-label')] = 0;
        }
      });
      Modules.push(arr);
    });
    
    //if (checkBtn.current.context._errors.length === 0) {
    
      var payloadData = {
        functionName: "AddUserRole",
        postData: {
          name: rolename,
          landingpage:$("#landingpage").val(),
          role_modules: Modules,
          role_functions: Functions
        },
      }
      setLoading(true);
      console.log("Add Role payload:-", payloadData)
      RoleService.addRole(payloadData).then((response) => {        
        console.log("API Response:-", response);
        setLoading(false);
        if(response.data.status){
          navigate("/roles-list")
          if(response.data.message !== '') {
            Alerts.swalSuccessAlert(response.data.message)
          }
          else {
            Alerts.swalSuccessAlert(t("Role added successfully."));
          }
        }
        else {
          if(response.data.message !== '') {
            if(response.data.message === "Unauthorized") {
              AuthService.logout();
              navigate("/login");
              window.location.reload();
            }
            else {
              Alerts.swalErrorAlert(response.data.message)
            }
          }
          else {
            Alerts.swalErrorAlert(t("Error: Can't add role at the moment. Please try again later."))
          }
        }
      },
      (error) => {
        setLoading(false);
        const resMessage = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
        Alerts.swalErrorAlert(t("Network Operation failed."))
      });

   // }
  };
  return (
    <>
      <div className="app-main__outer">
        <div className="app-main__inner">
          <div className="app-page-title">
            <div className="page-title-wrapper justify-content-center">
              <div className="page-title-heading fw-bold">
                <div className="page-title-icon">
                  <i className="pe-7s-plus icon-gradient bg-happy-green" />
                </div>
                <div>{t('Add New Role')}
                  <div className="page-title-subheading">
                  {t('Fill the form below to add a new Role.')}
                  </div>
                </div>
              </div>
            </div>
          </div>
      
          <div className="d-flex justify-content-center">
            <div className="col-md-12">
              <Card className="main-card mb-3">
                <CardBody>
                  <LoadingOverlay tag="div" active={loading}
                    styles = {{overlay: (base) => ({...base})}}
                    spinner = {<DNALoader/>}>  
                    <Form onSubmit={handleSubmit} ref={form}>
                      {!successful && (
                        <div>
                          <div className="form-group">
                            <div className="row">
                              <div className="col-md-6 col-xl-6" >
                                <label htmlFor="username" className="mb-2">{t('Name')}</label>
                                <Input
                                  type="text"
                                  className="form-control"
                                  name="rolename"
                                  placeholder={t('Role Name')}
                                  value={rolename}
                                  onChange={onChangeRolename}
                                  validations={[required, vrolename]}
                                  />
                              </div>
                              <div className="col-md-6 col-xl-6" >
                                <label htmlFor="username" className="mb-2">Select Landing</label>
                                <select className="form-select" id="landingpage" >
                                  <option disabled={true} value="">--Choose an option--</option>
                                  {modulesList && modulesList.map((module, index) => (
                                    <option value={module.route}>{module.name}</option>
                                  ))}
                                </select>
                              </div>
                            </div>
                          </div>
                          <br/>
                        </div>
                      )}
                      {modulesList &&
                      <Card body className="border-0 shadow-none mb-3 px-0">
                        <div className="row">
                          <h5 className="mb-3">{t('Assign Modules')}</h5>
                          {modulesList && modulesList.map((module, index) => (
                            <div className="accordion col-md-6 col-xl-6 pb-3" id={"accordion"+module._id}>
                              <div className="card">
                                <div className="card-header bg-light" id={"heading"+module._id}>
                                  
                                    <input className="form-check-input module-checkbox"
                                      type="checkbox" 
                                      name="modules[]"
                                      value={module._id}
                                      onChange={handleModuleChange}
                                    />
                                    {' '}
                                    <button className="btn btn-link toggle-btn mt-1" type="button" data-toggle="collapse" data-target={"#collapse"+module._id} aria-expanded="true" aria-controls={"collapse"+module._id} >
                                    {module.name}
                                    </button>
                                  {/* </h5> */}
                                </div>

                                <div id={"collapse"+module._id}  className="collapse-div collapse " aria-labelledby={"heading"+module._id} data-parent={"accordion"+module._id}>
                                  <div className="card-body ms-4 pt-1">
                                    
                                    {module.functions &&
                                      module.functions.map((func, index2) => {
                                        if(func.module_id === module._id) { 
                                          return (
                                            <div className="form-check mt-3">
                                              <input
                                                className={"form-check-input child-check-box-"+module._id}
                                                type="checkbox"
                                                data-label={func.name}
                                                value={func._id}
                                                onChange={handleFunctionChange}
                                              />
                                              <label className="form-check-label" htmlFor="users">
                                                {func.name}
                                              </label>
                                            </div>
                                          )
                                        }
                                      })
                                    }
                                  </div>
                                </div>
                              </div>
                            </div>
                          ))}

                          
                        </div>


                      </Card>
                      }
                      
                      <div className="form-group">
                        <div className="col-md-12 col-xl-12" >
                          <center>
                            <button type="button" onClick={() => navigate(-1)} className="btn btn-outline-light btn-hover-shine me-3 mt-3">{t('Cancel')}</button>
                            <button className="btn btn-custom-color btn-hover-shine me-3 mt-3" >{t('Add Role')}</button>
                          </center>
                        </div>
                      </div>
                      <CheckButton style={{ display: "none" }} ref={checkBtn} />
                    </Form>
                  </LoadingOverlay>
                </CardBody>
              </Card>
            </div>
          </div>
        </div>
      </div>
    </>
    
  );

};

export default AddRole;



