import axios from "axios";
import AuthService from "../Authentication/auth.service";


const API_URL  = process.env.REACT_APP_BASEURL;
const storeduser = JSON.parse(localStorage.getItem("user"));


const getAllUsers = () => {
  const headers= { 
    'Content-Type': 'application/json',
    'token': localStorage.getItem("session-id")
  }
  var payload = {
    functionName: "GetUsers",
    postData: {},
  }
  return axios.post(API_URL, payload, {headers});
};


const addUser = payload => {
  const headers= { 
    'Content-Type': 'application/json',
    'token': localStorage.getItem("session-id")
  }
  return axios.post(API_URL, payload, {headers});
};


const updateUser = payload => {
  const headers= { 
    'Content-Type': 'application/json',
    'token': localStorage.getItem("session-id")
  }
  return axios.post(API_URL, payload, {headers});
};


const deleteUser = id => {
  const headers= { 
    'Content-Type': 'application/json',
    'token': localStorage.getItem("session-id")
  }
  var payload = {
    functionName: "DeleteUser",
    postData: {
      "_id": id,
      "login_user_id": storeduser._id
    },
  }
  return axios.post(API_URL, payload, {headers});
};


const getSingleUser = id => {
  const headers= { 
    'Content-Type': 'application/json',
    'token': localStorage.getItem("session-id")
  }
  var payload = {
    functionName: "GetUsers",
    postData: {
      "_id": id,
    },
  }
  return axios.post(API_URL, payload, {headers});
};








const UserService = {
  getAllUsers,
  addUser,
  updateUser,
  deleteUser,
  getSingleUser
};

export default UserService;
