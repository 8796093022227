import React, { useState, Fragment } from "react";
import { Col, Row, Button, Label, FormGroup, Input } from "reactstrap";
import { Form, Field } from "react-final-form";
import LoadingOverlay from "react-loading-overlay-ts";
import DNALoader from "../../../components/Loaders/DnaLoader";
import "../../../assets/custom css/loadoverlay.scss";
import Alerts from "../../../components/Alerts/alerts";
import axios from "axios";
import { useNavigate } from "react-router-dom";

const Login_url  = process.env.REACT_APP_LOGINURL;

function RegisterProduct() {

    localStorage.removeItem("regprod");

    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
  
    const onSubmit = values => {
        console.log(values);
        setLoading(true);
        var payloadData = {
            functionName: "RegisterWaranty",
            postData: {
                "customer":{
                    "email": values.email,
                    "password": values.password,
                    "country": values.country,
                    "salutation": values.salutation,
                    "first_name": values.first_name,
                    "last_name": values.last_name,
                    "house_no": values.house_no,
                    "build_street": values.build_street,
                    "city": values.city,
                    "state": values.state,
                    "zip_code": values.zip_code,
                    "phone": values.phone
                },
                "prod_model": values.prod_model,
                "prod_serial_no": values.prod_serial_no,
                "seller_name": values.seller_name,
                "purchase_date": values.purchase_date,
                "install_date": values.install_date,
                "receipt": values.receipt
            }
        }
          return axios.post(Login_url + "customers", payloadData)
            .then((response) => {
                console.log("Register Warranty API Resp:-", response);
                setLoading(false);
                if(response.data.status){
                    if(response.data.message !== '') {
                        Alerts.swalSuccessAlert(response.data.message)
                    }
                    else {
                        Alerts.swalSuccessAlert("Warranty Registered successfully.");
                    }
                    navigate(-1);
                    window.location.reload();
                }
                else {
                    if(response.data.message !== '') {
                        Alerts.swalErrorAlert(response.data.message)
                    }
                    else {
                        Alerts.swalErrorAlert("Error: Can't register the warranty at the moment. Please try again later.")
                    }
                }
            },
            (error) => {
                setLoading(false);
                const resMessage = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
                console.log("Error:-", error)
                Alerts.swalErrorAlert("Network Operation failed.")
            });
    };
  
    const validateFtn = (values) => {
        const errors = {};
        function validateEmail(email) {
        var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
        }
        if (!values.email) {
        errors.email = "Email Required.";
        }
        else if (!validateEmail(values.email)) {
        errors.email = "Please enter a valid email address.";
        }
        if (!values.salutation) {
        errors.salutation = "Salutation Required.";
        }
        if (!values.first_name) {
            errors.first_name = "First/Given Name Required.";
        }
        if (!values.last_name) {
            errors.last_name = "Last/Family Name Required.";
        }
        if (!values.house_no) {
            errors.house_no = "This field is required.";
        }
        if (!values.build_street) {
            errors.build_street = "This field is required.";
        }
        if (!values.state) {
            errors.state = "State Required.";
        }
        if (!values.city) {
            errors.city = "City Required.";
        }
        if (!values.zip_code) {
            errors.zip_code = "ZIP Code Required.";
        }
        if (!values.country) {
            errors.country = "Country Required.";
        }
        if (!values.phone) {
            errors.phone = "Phone Required.";
        }
        if (!values.prod_model) {
            errors.prod_model = "Product Model Required.";
        }
        if (!values.prod_serial_no) {
            errors.prod_serial_no = "Product Serial No Required.";
        }
        if (!values.seller_name) {
            errors.seller_name = "Seller Name Required.";
        }
        if (!values.purchase_date) {
            errors.purchase_date = "Purchase Date Required.";
        }
        if (!values.install_date) {
            errors.install_date = "Installation Date Required.";
        }
        if (!values.receipt) {
            errors.receipt = "This value is Required.";
        }
        if (!values.accept) {
            errors.accept = "Please accept to proceed and register your warranty";
        }
        return errors;
    };

    return (
        <Fragment>
             <div className="app-main__outer">
                <div className="app-main__inner">
                    <Form onSubmit={onSubmit}
                        validate={validateFtn}
                        render={({ handleSubmit, values, submitting, validating, valid }) => (
                            <form onSubmit={handleSubmit}>
                                <LoadingOverlay tag="div" active={loading}
                                    styles = {{overlay: (base) => ({...base})}}
                                    spinner = {<DNALoader/>}>
                                    <div className="container">
                                        <div className="row">
                                            <div className="col-sm-10 col-md-8 col-lg-5">
                                            <h2 className="fs-2 my-4 fw-bold">Product Warranty</h2>
                                            
                                            <h4 className="fs-3 my-3"> Contact information</h4>
                                            <FormGroup>
                                                    <Field name="salutation">
                                                        {({ input, meta }) => (
                                                            <div className="mb-4">
                                                                <Label className="mb-2">Salutation *</Label>
                                                                <Input {...input} type="select" 
                                                                    invalid={meta.error && meta.touched} 
                                                                >
                                                                    <option disabled={true} value="">--Choose an option--</option>
                                                                    <option value="Mr.">Mr.</option>
                                                                    <option value="Mrs.">Mrs.</option>
                                                                    <option value="Miss.">Miss.</option>
                                                                </Input>
                                                                {meta.error && meta.touched && 
                                                                    <span className="text-danger">{meta.error}</span>}
                                                            </div>
                                                        )}
                                                    </Field>
                                                </FormGroup>
                                                <FormGroup>
                                                    <Field name="first_name">
                                                        {({ input, meta }) => (
                                                            <div className="mb-4">
                                                                <Label className="mb-2">First/Given Name *</Label>
                                                                <Input  {...input} type="text" className="form-control" 
                                                                invalid={meta.error && meta.touched} placeholder="" />
                                                                {meta.error && meta.touched && 
                                                                <span className="text-danger">{meta.error}</span>}
                                                            </div>
                                                        )}
                                                    </Field>
                                                </FormGroup>
                                                <FormGroup>
                                                    <Field name="last_name">
                                                        {({ input, meta }) => (
                                                            <div className="mb-4">
                                                                <Label className="mb-2">Last/Family Name *</Label>
                                                                <Input  {...input} type="text" className="form-control" 
                                                                invalid={meta.error && meta.touched} placeholder="" />
                                                                {meta.error && meta.touched && 
                                                                <span className="text-danger">{meta.error}</span>}
                                                            </div>
                                                        )}
                                                    </Field>
                                                </FormGroup>
                                                <FormGroup>
                                                    <Field name="house_no">
                                                        {({ input, meta }) => (
                                                            <div className="mb-4">
                                                                <Label className="mb-2">House No. / Lot No. / Floor / Block No. </Label>
                                                                <Input {...input} type="text" className="form-control" 
                                                                invalid={meta.error && meta.touched} placeholder="" />
                                                                {meta.error && meta.touched && 
                                                                <span className="text-danger">{meta.error}</span>}
                                                            </div>
                                                        )}
                                                    </Field>
                                                </FormGroup>
                                                <FormGroup>
                                                    <Field name="build_street">
                                                        {({ input, meta }) => (
                                                            <div className="mb-4">
                                                                <Label className="mb-2">Building Name/Street Adress *</Label>
                                                                <Input {...input} type="text" className="form-control" 
                                                                invalid={meta.error && meta.touched} placeholder="" />
                                                                {meta.error && meta.touched && 
                                                                <span className="text-danger">{meta.error}</span>}
                                                            </div>
                                                        )}
                                                    </Field>
                                                </FormGroup>
                                                <FormGroup>
                                                    <Field name="state">
                                                        {({ input, meta }) => (
                                                            <div className="mb-4">
                                                                <Label className="mb-2">State *</Label>
                                                                <Input {...input} type="text" className="form-control" placeholder=""
                                                                    invalid={meta.error && meta.touched} 
                                                                />
                                                                    {/* <option disabled={true} value="">--Choose an option--</option>
                                                                    <option value="1">Option 1</option>
                                                                    <option value="2">Option 2</option>
                                                                    <option value="3">Option 3</option>
                                                                </Input> */}
                                                                {meta.error && meta.touched && 
                                                                <span className="text-danger">{meta.error}</span>}
                                                            </div>
                                                        )}
                                                    </Field>
                                                </FormGroup>
                                                <FormGroup>
                                                    <Field name="city">
                                                        {({ input, meta }) => (
                                                            <div className="mb-4">
                                                                <Label className="mb-2">City *</Label>
                                                                <Input {...input} type="text" className="form-control" placeholder="" 
                                                                    invalid={meta.error && meta.touched} 
                                                                />
                                                                    {/* <option disabled={true} value="">--Choose an option--</option>
                                                                    <option value="1">Option 1</option>
                                                                    <option value="2">Option 2</option>
                                                                    <option value="3">Option 3</option>
                                                                </Input> */}
                                                                {meta.error && meta.touched && 
                                                                <span className="text-danger">{meta.error}</span>}
                                                            </div>
                                                        )}
                                                    </Field>
                                                </FormGroup>
                                                <FormGroup>
                                                    <Field name="zip_code">
                                                        {({ input, meta }) => (
                                                            <div className="mb-4">
                                                                <Label className="mb-2">Zip Code *</Label>
                                                                <Input {...input} type="text" className="form-control" 
                                                                 invalid={meta.error && meta.touched} placeholder="" />
                                                                {meta.error && meta.touched && 
                                                                <span className="text-danger">{meta.error}</span>}
                                                            </div>
                                                        )}
                                                    </Field>
                                                </FormGroup>
                                                <FormGroup>
                                                    <Field name="country">
                                                        {({ input, meta }) => (
                                                            <div className="mb-4">
                                                                <Label className="mb-2">Country *</Label>
                                                                <Input {...input} type="select" className="form-control" placeholder="" 
                                                                    invalid={meta.error && meta.touched} 
                                                                >
                                                                    <option disabled={true} value="">--Choose an option--</option>
                                                                    <option value="Malaysia">Malaysia</option>
                                                                    <option value="Singapore">Singapore</option>
                                                                    {/* <option value="3">Option 3</option> */}
                                                                </Input>
                                                                {meta.error && meta.touched && 
                                                                <span className="text-danger">{meta.error}</span>}
                                                            </div>
                                                        )}
                                                    </Field>
                                                </FormGroup>
                                                <FormGroup>
                                                    <Field name="phone">
                                                        {({ input, meta }) => (
                                                            <div className="mb-4">
                                                                <Label className="mb-2">Phone Number *</Label>
                                                                <Input {...input} type="text" className="form-control"
                                                                 invalid={meta.error && meta.touched} placeholder="" />
                                                                {meta.error && meta.touched && 
                                                                <span className="text-danger">{meta.error}</span>}
                                                            </div>
                                                        )}
                                                    </Field>
                                                </FormGroup>
                                                <FormGroup>
                                                    <Field name="email">
                                                        {({ input, meta }) => (
                                                            <div className="mb-4">
                                                                <Label className="mb-2">Email Address *</Label>
                                                                <Input {...input} type="text" className="form-control" 
                                                                 invalid={meta.error && meta.touched} placeholder="" />
                                                                {meta.error && meta.touched && 
                                                                <span className="text-danger">{meta.error}</span>}
                                                            </div>
                                                        )}
                                                    </Field>
                                                </FormGroup>

                                            <h4 className="fs-2 my-3"> Product Information </h4>
                                            
                                            <FormGroup>
                                                <Field name="prod_model">
                                                    {({ input, meta }) => (
                                                        <div className="mb-4">
                                                            <Label className="mb-2">Product Model *</Label>
                                                            <Input {...input} type="text" className="form-control" 
                                                             invalid={meta.error && meta.touched} placeholder="" />
                                                            {meta.error && meta.touched && 
                                                            <span className="text-danger">{meta.error}</span>}
                                                        </div>
                                                    )}
                                                </Field>
                                            </FormGroup>
                                            <FormGroup>
                                                <Field name="prod_serial_no">
                                                    {({ input, meta }) => (
                                                        <div className="mb-4">
                                                            <Label className="mb-2">Product Serial No.*</Label>
                                                            <Input {...input} type="text" className="form-control" 
                                                             invalid={meta.error && meta.touched} placeholder="" />
                                                            {meta.error && meta.touched && 
                                                            <span className="text-danger">{meta.error}</span>}
                                                        </div>
                                                    )}
                                                </Field>
                                            </FormGroup>
                                            <FormGroup>
                                                <Field name="seller_name">
                                                    {({ input, meta }) => (
                                                        <div className="mb-4">
                                                            <Label className="mb-2">Sellers Name *</Label>
                                                            <Input {...input} type="text" className="form-control" 
                                                             invalid={meta.error && meta.touched} placeholder="" />
                                                            {meta.error && meta.touched && 
                                                            <span className="text-danger">{meta.error}</span>}
                                                        </div>
                                                    )}
                                                </Field>
                                            </FormGroup>
                                            <FormGroup>
                                                <Field name="purchase_date">
                                                    {({ input, meta }) => (
                                                        <div className="mb-4">
                                                            <Label className="mb-2">Date Of Purchase *</Label>
                                                            <Input {...input} type="date" className="form-control" 
                                                             invalid={meta.error && meta.touched} placeholder="" />
                                                            {meta.error && meta.touched && 
                                                            <span className="text-danger">{meta.error}</span>}
                                                        </div>
                                                    )}
                                                </Field>
                                            </FormGroup>
                                            <FormGroup>
                                                <Field name="install_date">
                                                    {({ input, meta }) => (
                                                        <div className="mb-4">
                                                            <Label className="mb-2">Date Of Installation *</Label>
                                                            <Input {...input} type="date" className="form-control" 
                                                             invalid={meta.error && meta.touched} placeholder="" />
                                                            {meta.error && meta.touched && 
                                                            <span className="text-danger">{meta.error}</span>}
                                                        </div>
                                                    )}
                                                </Field>
                                            </FormGroup>
                                            <FormGroup>
                                                <Field name="receipt">
                                                    {({ input, meta }) => (
                                                        <div className="mb-4">
                                                            <Label className="mb-2">Purchase Receipt *</Label>
                                                            <Input {...input} type="select" className="form-control" placeholder="" 
                                                                invalid={meta.error && meta.touched} 
                                                            >
                                                                <option disabled={true} value="">--Choose an option--</option>
                                                                <option value="Yes">Yes</option>
                                                                <option value="No">No</option>
                                                            </Input>
                                                            {meta.error && meta.touched && 
                                                            <span className="text-danger">{meta.error}</span>}
                                                        </div>
                                                    )}
                                                </Field>
                                            </FormGroup>
                                            <FormGroup className="mb-5">
                                                <Field name="accept" type="checkbox">
                                                    {({ input, meta }) => (
                                                         <div className="mb-4">
                                                            <Input {...input} type="checkbox"
                                                             invalid={meta.error && meta.touched}  />
                                                            <Label for="exampleCheck">
                                                                By submitting the above information, I acknowledge and accept the terms of BSH Home Appliances Sdn. Bhd.'s 
                                                                <a href="void()">Privacy Policy</a> and <a href="void()">Terms and Conditions of Use</a> and agree to being 
                                                                contacted for the purposes of this form. I also acknowledge and accept the terms of BSH Home Appliances Sdn. Bhd.  
                                                                <a href="void()">Warrenty</a> and confirm that i am of legal age to consent to these terms. *
                                                            </Label>
                                                            {meta.error && meta.touched && 
                                                            <span className="text-danger">{meta.error}</span>}
                                                        </div>
                                                    )}
                                                </Field>
                                            </FormGroup>
                                            </div>
                                            <div className="d-flex justify-content-between mb-5">
                                                <FormGroup>
                                                    <div className="d-flex justify-content-center mb-5 mt-3">
                                                        <Button color="primary" className="me-3" type="submit" disabled={!valid}>
                                                            Submit
                                                        </Button>
                                                    </div>
                                                </FormGroup>
                                            </div>
                                        </div>
                                    </div>
                                </LoadingOverlay>
                            </form>
                        )}
                    />
                </div>
            </div>
        </Fragment>
    );

}
export default RegisterProduct;