import React, { useEffect, useState, Fragment } from "react";
import { useTranslation } from 'react-i18next';
import { useNavigate, useLocation } from "react-router-dom";

import Tabs, { TabPane } from "rc-tabs";
import TabContent from "rc-tabs/lib/SwipeableTabContent";
import ScrollableInkTabBar from "rc-tabs/lib/ScrollableInkTabBar";

import TabDetails from "./TabDetails";
import TabWarranty from "./TabWarranty";


const ViewCustomer = () => {

  const navigate = useNavigate();
  const { t, i18n } = useTranslation();

  const [customer, setCustomer] = useState("");
  // const [user, setUser] = useState("");

  const location = useLocation();
  const { state } = location;
  console.log("Received Object:-", state);

  useEffect(() => {
    i18n.changeLanguage(localStorage.getItem('language'));
    setData();
  }, []);

  function setData() {
    if (state) {
      console.log("Going to set data object for display")
      //   setLog(state)
      //   getuser(state.user_id);
      setCustomer(state)
    }
    else {
      navigate(-1)
      window.location.reload()
    }
  }

  //   function getuser(id) {
  //     UserService.getSingleUser(id).then(
  //       (response) => {
  //         console.log("Get User response:-", response)
  //         setUser(response.data.data);
  //       },
  //       (error) => {
  //         const errContent =
  //         (error.response && error.response.data) || error.message || error.toString();
  //       },
  //   )};

  return (
    <Fragment>
      <div className="app-main__outer">
        <div className="app-main__inner">
          <div className="app-page-title mb-0">
            <div className="page-title-wrapper">
              <div className="page-title-heading fw-bold">
                <div className="page-title-icon">
                  <i className="pe-7s-settings icon-gradient bg-happy-green" />
                </div>
                <div>
                {t('Customer & Warranty Details')}
                  <div className="page-title-subheading">
                  {t('View customer details and warranty details.')}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="d-flex justify-content-center">
            <div className="col-md-12">
              <Tabs defaultActiveKey="1" renderTabBar={() => <ScrollableInkTabBar />} renderTabContent={() => <TabContent />}>
                <TabPane tab={t('Details')} key="1">
                  <TabDetails />
                </TabPane>
                <TabPane tab={t('Warranty')} key="2">
                  <TabWarranty />
                </TabPane>
              </Tabs>
            </div>
          </div>
          <div className="col-md-12">
            <button type="button" onClick={() => navigate(-1)} className="btn btn-outline-success btn-hover-shine me-3 mt-3">
              {t('Go Back')}</button>
          </div>
        
        </div>
      </div>
    </Fragment>
  );

};
export default ViewCustomer;