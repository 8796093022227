
import axios from "axios";

const API_URL  = process.env.REACT_APP_BASEURL;
const storeduser = JSON.parse(localStorage.getItem("user"));

const headers = { 
  'Content-Type': 'application/json',
  'token': localStorage.getItem("session-id")
}

const getAll = (payload) => {
  return axios.post(API_URL, payload, {headers});
};

const getSingleCustomer = id => {
  const headers= { 
    'Content-Type': 'application/json',
    'token': localStorage.getItem("session-id")
  }
  var payload = {
    functionName: "GetCollectionData",
    postData: {
      "collection_name":"customers",
      "filter": {
        "_id": id
      }
    },
  }
  return axios.post(API_URL, payload, {headers});
};

const deleteCustomer = id => {
  const headers= { 
    'Content-Type': 'application/json',
    'token': localStorage.getItem("session-id")
  }
  var payload = {
    functionName: "DeleteCollectionData",
    postData: {
      "collection_name":"customers",
      "_id": id
    },
  }
  return axios.post(API_URL, payload, {headers});
};

function deleteWarranty(serial, customer_id) {
  const headers= { 
    'Content-Type': 'application/json',
    'token': localStorage.getItem("session-id")
  }
  var payload = {
    functionName: "UpdateCustomerWarranty",
    postData: {
      "_id": customer_id,
      "serial_number": serial
    },
  }
  return axios.post(API_URL, payload, {headers});
};


const CustomersService =  {
  getAll,
  deleteCustomer,
  deleteWarranty,
  getSingleCustomer
}
  
export default CustomersService;