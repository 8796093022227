import axios from "axios";

const API_URL  = process.env.REACT_APP_BASEURL;

const headers= { 
  'Content-Type': 'application/json',
  'token': localStorage.getItem("session-id")
}

const getAll = () => {
  var getData = {
    functionName: "GetCollectionData",
    postData: {
      collection_name: "templates"
    },
  }
  return axios.post(API_URL, getData, {headers});
};


const deleteTemplate = id => {
  var payload = {
    functionName: "DeleteCollectionData",
    postData: {
      _id: id,
      collection_name:"templates"
    },
  }
  return axios.post(API_URL, payload, {headers})
};


const addTemplate = payload => {
  console.log("Add Data:-", payload)
  return axios.post(API_URL, payload, {headers});
};


const updateTemplate = payload => {
  console.log("Update Data:-", payload)
  return axios.post(API_URL, payload, {headers});
}


const EmailService = {
  getAll,
  deleteTemplate,
  addTemplate,
  updateTemplate
};

export default EmailService;