import React, { useEffect, useState, Fragment } from "react";
import { useTranslation } from 'react-i18next';
import { useNavigate, Link } from "react-router-dom";
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import { Input, Row, Col, Card, CardBody } from "reactstrap";
import { format, parseISO, set } from 'date-fns';
import { CSVLink } from 'react-csv';

import LoadingOverlay from "react-loading-overlay-ts";
import DNALoader from "../../components/Loaders/DnaLoader";
import "../../assets/custom css/loadoverlay.scss";

import FilterByName from "../../components/Table Filters/FilterByName";
import FilterByEmail from "../../components/Table Filters/FilterByEmail";
import FilterByPhone from "../../components/Table Filters/FilterByPhone";
// import FilterByDate from "../../components/Table Filters/FilterByDate";
import FilterBySerial from "../../components/Table Filters/FilterBySerial";
import FilterByModel from "../../components/Table Filters/FilterByModel";
// import FilterByCountry from "../../components/Table Filters/FilterByCountry";
// import FilterByStatus from "../../components/Table Filters/FilterByStatus";

import DataTable from 'react-data-table-component';

import Alerts from "../../components/Alerts/alerts";

import CustomersService from "./customers.service";
import AuthService from "../Authentication/auth.service";


const API_URL  = process.env.REACT_APP_LOGINURL;

const CustomersList = () => {

  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [totalRows, setTotalRows] = useState(0);
  const [pageLimit, setPageLimit] = useState(10);
  const [currentPage, setCurrentPage] = useState("");
  const [CustomersList, setCustomers] = useState([]);
  const fileName = "customer-details";

  const headers = [
    { label: "Salutation", key: "salutation" },
    { label: "First Name", key: "first_name" },
    { label: "Last Name", key: "last_name" },
    { label: "Email", key: "email" },
    { label: "Phone Number", key: "phone" },
    { label: "House No./Lot No./Floor/Block No", key: "house_no"},
    { label: "Building Name/Street Address", key: "build_street"},
    { label: "City", key: "city"},
    { label: "State", key: "state"},
    { label: "Zip Code", key: "zip_code"},
    { label: "Country", key: "country"}
  ];

  useEffect(() => {
    i18n.changeLanguage(localStorage.getItem('language'));
    getCustomers(0, pageLimit)
  }, [pageLimit]);

  
  function getCustomers(page_index, page_limit) {
    var offset = (page_index -1 ) * page_limit;
    if(offset < 1 ) offset=0;
    var filter = {};
    var or_arr =[];
    if(filterTextEmail) {
      or_arr.push({"email": filterTextEmail})
    }

    if(filterTextName) {
      or_arr.push({"first_name": filterTextName})
    }

    if(filterTextPhone) {
      or_arr.push({"phone": filterTextPhone})
    }

    if(filterTextSerial) {
      or_arr.push({"warrantyData.prod_serial_no": filterTextSerial})
    }

    if(filterTextModel) {
      or_arr.push({"warrantyData.prod_model": filterTextModel})
    }
    if(or_arr.length){
      filter['$or'] = or_arr;
    }
    var payload = {
      functionName: "GetCollectionData",
      "postData":{
        "collection_name": "customers",
        "limit": page_limit,
        "offset": offset,
        "filter": filter
      }
    }
    fetchCustomers(payload);
  }

  function handlePageChange(page_index) {
    setCurrentPage(page_index)
    getCustomers(page_index, pageLimit);
  }

  async function handlePerRowsChange(newPageLimit, page_index) {
    setPageLimit(newPageLimit);
  }

  function fetchCustomers(payload) {
    setLoading(true);
    CustomersService.getAll(payload).then((response) => {
      setLoading(false);
      console.log("Get Customers response:-", response)
      if(response.data.message === "Unauthorized") {
        AuthService.logout();
        navigate("/login");
        window.location.reload();
      }
      else {
        setCustomers(response.data.data);
        setFilteredItems(response.data.data);
        setTotalRows(response.data.count.count);
      }
    },
    (error) => {
      setLoading(false);
      const errContent =
      (error.response && error.response.data) || error.message || error.toString();
      if(errContent) {
        Alerts.swalErrorAlert(errContent)
      }
      else {
        Alerts.swalErrorAlert(t("Server Error, Please try again later."))
      }
    })
  };


  function search_customers() {
    
      setTotalRows(0)
      setResetPaginationToggle(!resetPaginationToggle);
      var filter = {};
      var or_arr =[];
      if(filterTextEmail) {
        or_arr.push({"email": filterTextEmail})
      }

      if(filterTextName) {
        or_arr.push({"first_name": filterTextName})
      }

      if(filterTextPhone) {
        or_arr.push({"phone": filterTextPhone})
      }

      if(filterTextSerial) {
        or_arr.push({"warrantyData.prod_serial_no": filterTextSerial})
      }

      if(filterTextModel) {
        or_arr.push({"warrantyData.prod_model": filterTextModel})
      }
      if(or_arr.length){
        filter['$or'] = or_arr;
      }
      
      var payload = {
        functionName: "GetCollectionData",
        "postData":{
          "collection_name": "customers",
          "limit": pageLimit,
          "offset": 0,
          "filter": filter
        }
      }
    
   
    // var search_object = {
    //   email_filter : filterTextEmail,
    //   name_filter : filterTextName,
    //   phone_filter : filterTextPhone,
    //   date_filter : filterTextDate,
    //   serial_filter : filterTextSerial,
    // }
    // console.log("search object:-", search_object);
    console.log("search payload:-", payload);
    fetchCustomers(payload);

  };

  function confirmDelete(_id) {
    let msg = t("Once deleted, you will not be able to recover this customer!")
    Alerts.swalConfirmDeleteAlert(msg).then(res => {
      console.log("Resp:-",res)
      if(res === true) {
        sendDelete(_id)
      }
    }) 
  };

  function sendDelete(id) {
    setLoading(true);
    CustomersService.deleteCustomer(id).then((response) => {
      console.log("Delete Customer response:-", response)
      setLoading(false);
      getCustomers(currentPage, pageLimit)
      if(response.data.status) {
        if(response.data.message !== '') {
          Alerts.swalSuccessAlert(response.data.message)
        }
        else {
          Alerts.swalSuccessAlert(t("Customer Deleted Successfully."))
        }
        console.log(response.data.message)
      }
      else {
        if(response.data.message !== '') {
          if(response.data.message === "Unauthorized") {
            AuthService.logout();
            navigate("/login");
            window.location.reload();
          }
          else {
            Alerts.swalErrorAlert(response.data.message);
          }
        }
        else {
          Alerts.swalErrorAlert(t("Error: Can't delete the Customer."))
        }
      }
    },
    (error) => {
      setLoading(false);
      const errContent =
      (error.response && error.response.data) || error.message || error.toString();
      Alerts.swalErrorAlert(t("Delete operation failed"))
    })
  };


  const TBcolumns = [
    {
      name: t('Email'),
      width: '20%',
      selector: row => row.email,
    },
    {
      name: t('Full Name'),
      width: '18%',
      selector: row => row.first_name+' '+row.last_name,
    },
    {
      name: t('Phone Number'),
      width: '14%',
      selector: row => row.phone,
    },
    // {
    //   name: t('Registration Date'),
    //   selector: row => format((row.warrantyData[0].reg_date, new Date()), "dd-MMM-yyyy"),
    // },
    {
      name: t('Model Number'),
      // selector: row => row.warrantyData[0].prod_model,
      selector: (row) => {
        return(
          <div>
            {row &&
              row.warrantyData.map((prodrow, ind) => (
                <p className="mt-2 mb-2">{prodrow.prod_model}</p>
              )
            )}
          </div>
        )
      }
    },
    {
      name: t('Product Serial Number'),
      selector: (row) => {
        return(
          <div>
            {row &&
              row.warrantyData.map((myrow, inde) => (
                <p className="mt-2 mb-2">{myrow.prod_serial_no}</p>
              )
            )}
          </div>
        )
        
        // if(row.warrantyData.length === 1) {
        //   return(
        //     <div>{row.warrantyData[0].prod_serial_no}</div>
        //   )
        // }
        // else {
        //   return(
        //     <select className="form-select">
        //       {/* <option >{row.warrantyData[0].prod_serial_no}</option> */}
        //       {row &&
        //         row.warrantyData.map((myrow, inde) => (
        //           <option >{myrow.prod_serial_no}</option>
        //         )
        //       )}
        //     </select>
        //   )
        // }
      }
    },
    // {
    //   name: t('Country'),
    //   selector: row => row.country,
    // },
    // {
    //   name: t('Status'),
    //   width: '14%',
    //   selector: (row) =>  (
    //     <div>
    //       {row.status === "Active" &&
    //         <button className="btn btn-success btn">{row.status}
    //         </button>
    //       }
    //       {row.status === "Deactivated" &&
    //         <button className="btn btn-danger btn">{row.status}
    //         </button>
    //       }
    //     </div>
    //     ),
    // },
    {
    name: t('Actions'),
    width: '12%',
    selector:  (row) =>  {
      return (
        <div>
          <Link to='/customer-details' state= {row} className="ViewCustomerWarranty" >
            <i className="pe-7s-news-paper btn-icon-wrapper icon-gradient bg-arielle-smile btn-lg px-1"> </i>
          </Link>
          <button className="btn text-info px-0 py-0 mb-0">
            <CSVLink
                    headers={headers}
                    data= {[row]}
                    filename={fileName}
                    style={{ "textDecoration": "none", "color": "#000" }}
                    className={"ExportDataSingle"}
                >
              <i className="pe-7s-download btn-icon-wrapper icon-gradient bg-plum-plate btn-lg px-1 py-0 mb-2 "> </i>
            </CSVLink>
          </button>
          <a className="DeleteData" onClick={() => confirmDelete(row._id)} style={{cursor: "pointer"}}>
            <i className="pe-7s-trash icon-gradient bg-ripe-malin btn-lg px-1 py-0"> </i>
          </a>
            
        </div>
        
      );
    }
    },
  ];


  const [filteredItems, setFilteredItems] = useState([]);
  const [filterTextEmail, setfilterTextEmail] = React.useState('');
  const [filterTextName, setfilterTextName] = React.useState('');
  const [filterTextPhone, setfilterTextPhone] = React.useState('');
  // const [filterTextDate, setfilterTextDate] = React.useState('');
  const [filterTextModel, setfilterTextModel] = React.useState('');
  const [filterTextSerial, setfilterTextSerial] = React.useState('');
  // const [filterTextCountry, setfilterTextCountry] = React.useState('');
  // const [filterTextStatus, setfilterTextStatus] = React.useState('');
  const [resetPaginationToggle, setResetPaginationToggle] = React.useState(false);

  function handleClear(param) {
    // setFilteredItems(CustomersList)
    if(param === 'email') {
      setResetPaginationToggle(!resetPaginationToggle);
      setfilterTextEmail('');
    }
    if(param === 'name') {
      setResetPaginationToggle(!resetPaginationToggle);
      setfilterTextName('');
    }
    if(param === 'phone') {
      setResetPaginationToggle(!resetPaginationToggle);
      setfilterTextPhone('');
    }
    // if(param === 'date') {
    //   setResetPaginationToggle(!resetPaginationToggle);
    //   setfilterTextDate('');
    // }
    if(param === 'serial') {
      setResetPaginationToggle(!resetPaginationToggle);
      setfilterTextSerial('');
    }
    if(param === 'model') {
      setResetPaginationToggle(!resetPaginationToggle);
      setfilterTextModel('');
    }
    // if (filterTextCountry) {
    //   setResetPaginationToggle(!resetPaginationToggle);
    //   setfilterTextCountry('');
    // }
    // if (filterTextStatus) {
    //   setResetPaginationToggle(!resetPaginationToggle);
    //   setfilterTextStatus('');
    // }
  };
 
  const subHeaderComponentMemo = React.useMemo(() => {
    return (
      <>
        <FilterByEmail onFilterEmail={e => setFilterTextFtn(e.target.value, 'email')} onClear={e => handleClear('email')} filterTextEmail={filterTextEmail} />
        <FilterByName onFilterName={e => setFilterTextFtn(e.target.value, 'name')} onClear={e => handleClear('name')} filterTextName={filterTextName} />
        <FilterByPhone onFilterPhone={e => setFilterTextFtn(e.target.value, 'phone')} onClear={e => handleClear('phone')} filterTextPhone={filterTextPhone}/>
        {/* <FilterByDate onFilterDate={e => setFilterTextFtn(e.target.value, 'date')} onClear={e => handleClear('date')} filterTextDate={filterTextDate} /> */}
        <FilterByModel onFilterModel={e => setFilterTextFtn(e.target.value, 'model')} onClear={e => handleClear('model')} filterTextModel={filterTextModel} />
        <FilterBySerial onFilterSerial={e => setFilterTextFtn(e.target.value, 'serial')} onClear={e => handleClear('serial')} filterTextSerial={filterTextSerial} />
        {/* <FilterByCountry onFilterCountry={e => setFilterTextFtn(e.target.value, 'country')} onClear={handleClear} filterTextCountry={filterTextCountry}/>
        <FilterByStatus onFilterStatus={e => setFilterTextFtn(e.target.value, 'status')} onClear={handleClear} filterTextStatus={filterTextStatus} /> */}
      </>  
    );
  }, [filterTextEmail, filterTextName, filterTextPhone, filterTextModel, filterTextSerial, resetPaginationToggle]);

  function setFilterTextFtn(itemrec, param) {
    console.log('item:-', itemrec)
    if(param === 'email') {
      setfilterTextEmail(itemrec);
      const filterItems = CustomersList.filter(
        item => item.email && item.email.toLowerCase().includes(itemrec.toLowerCase()),
      );
      setFilteredItems(filterItems);
    }
    if(param === 'name') {
      setfilterTextName(itemrec);
      const filterItems = CustomersList.filter(
        item => item.first_name && item.first_name.toLowerCase().includes(itemrec.toLowerCase()),
      );
      setFilteredItems(filterItems);
    }
    if(param === 'phone') {
      setfilterTextPhone(itemrec);
      const filterItems = CustomersList.filter(
        item => item.phone && item.phone.toLowerCase().includes(itemrec.toLowerCase()),
      );
      setFilteredItems(filterItems);
    }
    // if(param === 'date') {
    //   setfilterTextDate(itemrec);
    //   const filterItems = CustomersList.filter(
    //     item => format((item.warrantyData[0].reg_date, new Date()), "yyyy-MM-dd").includes(itemrec),
    //   );
    //   setFilteredItems(filterItems);
    // }
    if(param === 'serial') {
      setfilterTextSerial(itemrec);
      const filterItems = CustomersList.filter(
        item => item.warrantyData[0].prod_serial_no && item.warrantyData[0].prod_serial_no.toLowerCase().includes(itemrec.toLowerCase()),
      );
      setFilteredItems(filterItems);
    }
    if(param === 'model') {
      setfilterTextModel(itemrec);
      const filterItems = CustomersList.filter(
        item => item.warrantyData[0].prod_model && item.warrantyData[0].prod_model.toLowerCase().includes(itemrec.toLowerCase()),
      );
      setFilteredItems(filterItems);
    }
    // if(param === 'country') {
    //   setfilterTextCountry(itemrec);
    //   const filterItems = CustomersList.filter(
    //     item => item.country && item.country.toLowerCase().includes(itemrec.toLowerCase()),
    //   );
    //   setFilteredItems(filterItems);
    // }
    // if(param === 'status') {
    //   setfilterTextStatus(itemrec);
    //   const filterItems = CustomersList.filter(
    //     item => item.status && item.status.toLowerCase().includes(itemrec.toLowerCase()),
    //   );
    //   setFilteredItems(filterItems);
    // }
  };


  return (
    <Fragment>
      <div className="app-main__outer">
        <div className="app-main__inner">
          <TransitionGroup>
            <CSSTransition component="div" classNames="TabsAnimation" appear={true}
              timeout={1500} enter={false} exit={false}>
              <div>
                <div className="app-page-title">
                  <div className="page-title-wrapper">
                    <div className="page-title-heading fw-bold">
                      <div className="page-title-icon">
                        <i className="pe-7s-users icon-gradient bg-happy-green" />
                      </div>
                      <div>
                      {t('Customers')}
                        <div className="page-title-subheading">
                          {t('View all customers with basic details.')}
                        </div>
                      </div>
                    </div>
                    <div className="page-title-actions">
                      <a href={API_URL+'customers_csv'} target="_blank" className="ExportData">
                        <button type="button" className="btn btn-custom-color btn-hover-shine me-3 btn">Export</button>
                      </a>
                    </div>
                  </div>
                </div>
                <Row>
                  <Col md="12">
                    <Card className="main-card mb-3">
                      {/* <Row className="mt-3 ms-3">
                        <Col md="7"  style={{textAlign:"start"}}>
                          <button type="button" onClick={() => navigate(-1)} className="btn btn-outline-success btn-hover-shine me-3">
                            {t('Go Back')}
                          </button>
                        </Col>
                        <Col md="2" className="me-2" >
                          <input
                              type='text' className="form-control" name="searchfield" placeholder={t('Search ...')}
                              id="searchfield" onKeyUp={search_customer}
                            />
                        </Col>
                        <Col md="2" className="ms-1" >
                          <Input type='select' className="form-control" onChange={filter_change} >
                            <option value="">Search Filter</option>
                            <option key="0" value ="menu">Filter By Email</option>
                            <option key="1" value ="1">Filter By Name</option>
                            <option key="2" value ="2">Filter By Phone</option>
                            <option key="3" value ="3">Filter By Serial No</option>
                          </Input>
                        </Col>
                      </Row> */}
                      <CardBody>
                        <LoadingOverlay tag="div" active={loading}
                          styles = {{overlay: (base) => ({...base})}}
                          spinner = {<DNALoader/>}>
                          <Col md="1"  style={{float:"left", position:"absolute", zIndex:"1"}}>
                            <button type="button" onClick={() => navigate("/home")} className="btn btn-outline-success btn-hover-shine me-3">
                              {t('Go Back')}
                            </button>
                        </Col>
                        <Col md="1" className="mt-1"  style={{right:"0", position:"absolute", zIndex:"1"}}>
                          <button type="button" onClick={search_customers} className="btn btn-custom-color btn-hover-shine me-3 btn">
                            Search
                          </button>
                        </Col>
                          <DataTable style={{justifyContent:"center"}}
                            columns={TBcolumns}
                            data={CustomersList}
                            pagination
                            paginationServer
                            paginationTotalRows={totalRows}
                            onChangePage={handlePageChange}
                            onChangeRowsPerPage={handlePerRowsChange}
                            paginationResetDefaultPage={resetPaginationToggle} // optionally, a hook to reset pagination to page 1
                            subHeader
                            subHeaderComponent={subHeaderComponentMemo}
                            selectableRowDisabled={true}
                            persistTableHead
                          />
                        </LoadingOverlay>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              </div>
            </CSSTransition>
          </TransitionGroup>
        </div>
      </div>
    </Fragment>
  );

};

export default CustomersList;




