import React, { useState, useEffect, Fragment } from "react";
import cx from "classnames";
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import AuthService from "../../pages/Authentication/auth.service";
import { useNavigate } from "react-router-dom";
import { useTranslation } from 'react-i18next';

import PerfectScrollbar from "react-perfect-scrollbar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faAngleDown, faUser, faUserEdit } from "@fortawesome/free-solid-svg-icons";
import { IoIosGrid, IoIosNotifications} from "react-icons/io";
import Flag from "react-flagkit";

import city3 from "../../assets/utils/images/dropdown-header/city3.jpg";
import city2 from "../../assets/utils/images/dropdown-header/city2.jpg";
import user from "../../assets/utils/images/avatars/user_gen.png";

import {
  VerticalTimeline,
  VerticalTimelineElement,
} from "react-vertical-timeline-component";
import {
  DropdownToggle,
  DropdownMenu,
  Nav,
  Col,
  Row,
  Button,
  NavItem,
  NavLink,
  UncontrolledTooltip,
  UncontrolledButtonDropdown,
  UncontrolledDropdown,
  DropdownItem
} from "reactstrap";

library.add(
  faUser,
  faUserEdit
  );


const Topbar = () => {

  const { t, i18n } = useTranslation();

  useEffect(() => {
    setLanguageFlag(localStorage.getItem("language"));
  }, []);

  const navigate = useNavigate();
  const currentUser = AuthService.getCurrentUser();
  console.log("curr", currentUser)
  const [logo, setLogo] = useState(localStorage.getItem("system_logo"));
  const [langFlag, setlangFlag] = useState('');
  const [active1, setActive1] = useState(false);
  const [active2, setActive2] = useState(false);

  function setLanguageFlag(lang) {
    if(lang == 'en') {
      setlangFlag('GB');
      setActive1(true)
      setActive2(false)
    }
    else {
      setlangFlag('MY');
      setActive1(false)
      setActive2(true)
    }
  };

  function changeLanguage(lang) {
    console.log('language:-', lang);
    localStorage.setItem('language', lang);
    setLanguageFlag(lang);
    i18n.changeLanguage(lang);
  }

  function logOut() {
    AuthService.logout();
    navigate("/login");
    window.location.reload();
  };

  return (
    <Fragment>
      <TransitionGroup>
        <CSSTransition component="div" 
        className={cx("app-header header-shadow")}
          appear={true} timeout={1500} enter={false} exit={false}>
          <div>  
            <div className="app-header__logo">
              <div>
                <img src={logo}  className="logo-src" alt="logo"/>
              </div>
              <div className="header__pane ms-auto">
            </div>
          </div>
            <div
              className={cx("app-header__content", {
                // "header-mobile-open": false,
              })}>
              <div className="app-header-right">
                {/* <div className="header-dots">
                  <UncontrolledDropdown>
                    <DropdownToggle className="p-0 me-2" color="link">
                      <div className="icon-wrapper icon-wrapper-alt rounded-circle">
                        <div className="icon-wrapper-bg bg-focus" />
                        <div className="language-icon">
                          <Flag className="me-3 opacity-8" country={langFlag} size="40" />
                        </div>
                      </div>
                    </DropdownToggle>
                    <DropdownMenu end className="rm-pointers">
                      <div className="dropdown-menu-header">
                        <div className="dropdown-menu-header-inner pt-4 pb-4 bg-focus">
                          <div className="menu-header-image opacity-05"
                            style={{
                              backgroundImage: "url(" + city2 + ")",
                            }}/>
                          <div className="menu-header-content text-center text-white">
                            <h6 className="menu-header-subtitle mt-0">
                              {t('Choose Language')}
                            </h6>
                          </div>
                        </div>
                      </div>
                      <DropdownItem active={active1} onClick={()=>changeLanguage('en')}>
                        <Flag className="me-3 opacity-8" country="GB"  />
                        English (UK)
                      </DropdownItem>
                      <DropdownItem active={active2} onClick={()=>changeLanguage('bm')}>
                        <Flag className="me-3 opacity-8" country="MY"  />
                        Malaysia
                      </DropdownItem>
                    </DropdownMenu>
                  </UncontrolledDropdown>
                  <UncontrolledDropdown>
                    <DropdownToggle className="p-0" color="link">
                    <div className="icon-wrapper icon-wrapper-alt rounded-circle">
                      <div className="icon-wrapper-bg bg-success" />
                        <IoIosNotifications color="#3ac47d" fontSize="23px" />
                      </div>
                    </DropdownToggle>
                    <DropdownMenu end className="rm-pointers dropdown-menu-lg">
                      <div className="dropdown-menu-header mb-0">
                        <div className="dropdown-menu-header-inner bg-strong-bliss">
                          <div className="menu-header-image opacity-1"
                            style={{
                              backgroundImage: "url(" + city2 + ")",
                            }}/>
                          <div className="menu-header-content text-white">
                            <h5 className="menu-header-title">{t('Notifications')}</h5>
                            <h6 className="menu-header-subtitle">
                              {t('You have')} <b className="text-warning">21 </b>
                              {t('unread Notifications')}
                            </h6>
                          </div>
                        </div>
                      </div>
                      <div className="scroll-area-sm">
                        <PerfectScrollbar>
                          <div className="p-4">
                            <VerticalTimeline layout="1-column">
                              <VerticalTimelineElement className="vertical-timeline-item"
                                  icon={
                                    <i className="badge badge-dot badge-dot-xl bg-success"> {" "} </i>
                                  }
                                  date="10:30 PM">
                                  <h4 className="timeline-title">Crud Created</h4>
                                  <p>
                                    A new crud is created by farrukh@syslabtechnologies.com. 
                                  </p>
                              </VerticalTimelineElement>
                              <VerticalTimelineElement className="vertical-timeline-item"
                                icon={
                                  <i className="badge badge-dot badge-dot-xl bg-warning"> {" "} </i>
                                }
                                date="12:25 PM">
                                <h4 className="timeline-title">
                                  New Configuration
                                </h4>
                                <p>
                                  Configuration added by Super Admin Yasir Mehmood.
                                </p>
                              </VerticalTimelineElement>
                              <VerticalTimelineElement className="vertical-timeline-item"
                                icon={
                                  <i className="badge badge-dot badge-dot-xl bg-danger"> {" "} </i>
                                }
                                date="15:00 PM">
                                <h4 className="timeline-title">
                                  Password Changed
                                </h4>
                                <p>
                                  You have successfully reset the password.
                                </p>
                              </VerticalTimelineElement>
                            </VerticalTimeline>
                          </div>
                        </PerfectScrollbar>
                      </div>
                    </DropdownMenu>
                  </UncontrolledDropdown>
                </div> */}

                <div className="header-btn-lg pe-0">
                  <div className="widget-content p-0">
                    <div className="widget-content-wrapper">
                      <div className="widget-content-left">
                        <UncontrolledButtonDropdown>
                          <DropdownToggle color="link" className="p-0">
                            <img width={28} src={user} alt=""/>
                            <FontAwesomeIcon
                              className="ms-2 opacity-8"
                              icon={faAngleDown}
                            />
                          </DropdownToggle>
                          <DropdownMenu end className="rm-pointers dropdown-menu-lg">
                            <div className="dropdown-menu-header">
                              <div className="dropdown-menu-header-inner bg-info">
                                <div className="menu-header-image opacity-2"
                                  style={{
                                    backgroundImage: "url(" + city3 + ")",
                                  }}/>
                                <div className="menu-header-content text-start">
                                  <div className="widget-content p-0">
                                    <div className="widget-content-wrapper">
                                      <div className="widget-content-left me-3">
                                        <img width={62} className="rounded-circle" src={user} alt=""/>
                                      </div>
                                      <div className="widget-content-left">
                                        {currentUser &&
                                          <div className="widget-heading">
                                            {currentUser.name}
                                          </div>
                                        }
                                        <div className="widget-subheading opacity-8">
                                          {currentUser?.role_details?.name}
                                        </div>
                                      </div>
                                      <div className="widget-content-right me-2">
                                        <Button className="btn-pill btn-shadow btn-custom-color btn-shine" onClick={logOut}>
                                        {t('Logout')}
                                        </Button>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="scroll-area-xs"
                              style={{
                                height: "80px",
                              }}>
                              <PerfectScrollbar>
                                <Nav vertical>
                                  <NavItem>
                                    <NavLink href="/my-profile">
                                      <FontAwesomeIcon icon={faUserEdit} className="px-2 py-0" style={{marginBottom:"3px"}}/>
                                    {t('My Profile')}
                                    </NavLink>
                                  </NavItem>
                                </Nav>
                              </PerfectScrollbar>
                            </div>
                            {/* <div className="scroll-area-xs"
                              style={{
                                height: "180px",
                              }}>
                              <PerfectScrollbar>
                                <Nav vertical>
                                  <NavItem className="nav-item-header">
                                  {t('My Account')}
                                  </NavItem>
                                  <NavItem>
                                    <NavLink href="/profile">
                                    {t('Profile')}
                                    </NavLink>
                                  </NavItem>
                                  <NavItem>
                                    <NavLink href="/home">
                                    {t('Settings')}
                                    </NavLink>
                                  </NavItem>
                                  <NavItem className="nav-item-header">
                                  {t('Activity')}
                                  </NavItem>
                                  <NavItem>
                                    <NavLink href="/home">
                                      {t('Logs')}
                                    </NavLink>
                                  </NavItem>
                                </Nav>
                              </PerfectScrollbar>
                            </div> */}
                            
                          </DropdownMenu>
                        </UncontrolledButtonDropdown>
                      </div>
                    </div>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </CSSTransition>
      </TransitionGroup>
    </Fragment>
  );







  // const navigate = useNavigate();
  // const currentUser = AuthService.getCurrentUser();
  
  // const [showModeratorBoard, setShowModeratorBoard] = useState(false);
  // const [showAdminBoard, setShowAdminBoard] = useState(false);

  // useEffect(() => {

  //   if (currentUser) {
  //     setShowModeratorBoard(currentUser.roles.includes("ROLE_MODERATOR"));
  //     setShowAdminBoard(currentUser.roles.includes("ROLE_ADMIN"));
  //   }
    

  //   EventBus.on("logout", () => {
  //     logOut();
  //   });

  //   return () => {
  //     EventBus.remove("logout");
  //   };
  // }, []);

  // function gotoprofile() {
  //   navigate("/profile");
  //   // window.location.reload();
  // }

  // function gotosettings() {
  //   navigate("/settings");
  //   // window.location.reload();
  // }

  // function logOut() {
  //   AuthService.logout();
  //   setShowModeratorBoard(false);
  //   setShowAdminBoard(false);
  //   navigate("/login");
  //   window.location.reload();
  // };

  // const baseURL = process.env.PUBLIC_URL;

  // // render() {
  //   return (
  //     <div className="topbar">
      
  //       <nav className="navbar-custom" id="navbar-custom">
  //         <ul className="list-unstyled topbar-nav float-end mb-0">
  //           <li className="dropdown notification-list">
  //             <a
  //               className="nav-link dropdown-toggle arrow-none nav-icon"
  //               data-bs-toggle="dropdown"
  //               href="/home"
  //               role="button"
  //               aria-haspopup="false"
  //               aria-expanded="false"
  //             >
  //               <i className="ti ti-bell" />
  //               <span className="alert-badge" />
  //             </a>
  //             <div className="dropdown-menu dropdown-menu-end dropdown-lg pt-0">
  //               <h6 className="dropdown-item-text font-15 m-0 py-3 border-bottom d-flex justify-content-between align-items-center">
  //                 Notifications{" "}
  //                 <span className="badge bg-soft-primary badge-pill">2</span>
  //               </h6>
  //               <div className="notification-menu" data-simplebar>
                
  //                 <a href="/home" className="dropdown-item py-3">
  //                   <small className="float-end text-muted ps-2">
  //                     2 min ago
  //                   </small>
  //                   <div className="media">
  //                     <div className="avatar-md bg-soft-primary">
  //                       <i className="ti ti-chart-arcs" />
  //                     </div>
  //                     <div className="media-body align-self-center ms-2 text-truncate">
  //                       <h6 className="my-0 fw-normal text-dark">
  //                         Your order is placed
  //                       </h6>
  //                       <small className="text-muted mb-0">
  //                         Dummy text of the printing and industry.
  //                       </small>
  //                     </div>
                    
  //                   </div>
                  
  //                 </a>
                
                
  //                 <a href="/home" className="dropdown-item py-3">
  //                   <small className="float-end text-muted ps-2">
  //                     10 min ago
  //                   </small>
  //                   <div className="media">
  //                     <div className="avatar-md bg-soft-primary">
  //                       <i className="ti ti-device-computer-camera" />
  //                     </div>
  //                     <div className="media-body align-self-center ms-2 text-truncate">
  //                       <h6 className="my-0 fw-normal text-dark">
  //                         Meeting with designers
  //                       </h6>
  //                       <small className="text-muted mb-0">
  //                         It is a long established fact that a reader.
  //                       </small>
  //                     </div>
                    
  //                   </div>
                  
  //                 </a>
                
                
  //                 <a href="/home" className="dropdown-item py-3">
  //                   <small className="float-end text-muted ps-2">
  //                     40 min ago
  //                   </small>
  //                   <div className="media">
  //                     <div className="avatar-md bg-soft-primary">
  //                       <i className="ti ti-diamond" />
  //                     </div>
  //                     <div className="media-body align-self-center ms-2 text-truncate">
  //                       <h6 className="my-0 fw-normal text-dark">
  //                         UX 3 Task complete.
  //                       </h6>
  //                       <small className="text-muted mb-0">
  //                         Dummy text of the printing.
  //                       </small>
  //                     </div>
                    
  //                   </div>
                  
  //                 </a>
                
                
  //                 <a href="/home" className="dropdown-item py-3">
  //                   <small className="float-end text-muted ps-2">
  //                     1 hr ago
  //                   </small>
  //                   <div className="media">
  //                     <div className="avatar-md bg-soft-primary">
  //                       <i className="ti ti-drone" />
  //                     </div>
  //                     <div className="media-body align-self-center ms-2 text-truncate">
  //                       <h6 className="my-0 fw-normal text-dark">
  //                         Your order is placed
  //                       </h6>
  //                       <small className="text-muted mb-0">
  //                         It is a long established fact that a reader.
  //                       </small>
  //                     </div>
                    
  //                   </div>
                  
  //                 </a>
                
                
  //                 <a href="/home" className="dropdown-item py-3">
  //                   <small className="float-end text-muted ps-2">
  //                     2 hrs ago
  //                   </small>
  //                   <div className="media">
  //                     <div className="avatar-md bg-soft-primary">
  //                       <i className="ti ti-users" />
  //                     </div>
  //                     <div className="media-body align-self-center ms-2 text-truncate">
  //                       <h6 className="my-0 fw-normal text-dark">
  //                         Payment Successfull
  //                       </h6>
  //                       <small className="text-muted mb-0">
  //                         Dummy text of the printing.
  //                       </small>
  //                     </div>
                    
  //                   </div>
                  
  //                 </a>
                
  //               </div>
              
  //               <a
  //                 href="/home"
  //                 className="dropdown-item text-center text-primary"
  //               >
  //                 View all <i className="fi-arrow-right" />
  //               </a>
  //             </div>
  //           </li>
  //           <li className="dropdown">
  //             <a
  //               className="nav-link dropdown-toggle nav-user"
  //               data-bs-toggle="dropdown"
  //               href="/home"
  //               role="button"
  //               aria-haspopup="false"
  //               aria-expanded="false"
  //             >
  //               <div className="d-flex align-items-center">
  //                 <img
  //                   src={baseURL+"../assets/images/users/user-4.jpg"}
  //                   alt="profile-user"
  //                   className="rounded-circle me-2 thumb-sm"
  //                 />
  //                 <div>
                    
  //                 <UncontrolledButtonDropdown>
  //                   <DropdownToggle caret="true" className="mb-2 me-2" color="link">
  //                   <small className="d-none d-md-block font-11">Admin
  //                   </small>
  //                   <span className="d-none d-md-block fw-semibold font-12">
  //                   {currentUser.name} <i className="mdi mdi-chevron-down" />
  //                   </span>
  //                   </DropdownToggle>
  //                   <DropdownMenu className="dropdown-menu-rounded">
  //                     <DropdownItem onClick={gotoprofile}>Profile</DropdownItem>
  //                     <DropdownItem onClick={gotosettings}>Settings</DropdownItem>
  //                     <DropdownItem onClick={logOut}>Logout</DropdownItem>
  //                   </DropdownMenu>
  //                 </UncontrolledButtonDropdown>
  //                   {/* <small className="d-none d-md-block font-11">Admin
  //                   </small>
  //                   <span className="d-none d-md-block fw-semibold font-12">
  //                   {currentUser.name} <i className="mdi mdi-chevron-down" />
  //                   </span> */}
  //                 </div>
  //               </div>
  //             </a>
              
  //             {/* <div className="dropdown-menu dropdown-menu-end">
  //               <a className="dropdown-item" href={"/profile"}>
  //                 <i className="ti ti-user font-16 me-1 align-text-bottom" />
  //                 Profile
  //               </a>
  //               <a className="dropdown-item" href="/settings">
  //                 <i className="ti ti-settings font-16 me-1 align-text-bottom" />
  //                 Settings
  //               </a>
  //               <div className="dropdown-divider mb-0" />
  //               <a className="dropdown-item" href={"/login"} onClick={logOut}>
  //                 <i className="ti ti-power font-16 me-1 align-text-bottom" />
  //                 Logout
  //               </a>
  //             </div> */}
  //           </li>
          
  //         </ul>
        
  //         <ul className="list-unstyled topbar-nav mb-0">
  //           <li>
  //             <button
  //               className="nav-link button-menu-mobile nav-icon"
  //               id="togglemenu"
  //             >
  //               <i className="ti ti-menu-2" />
  //             </button>
  //           </li>
  //         </ul>
  //       </nav>
      
  //     </div>
  //   );
    // }
  }
  
  export default Topbar;
  