import React, { useEffect, useState, Fragment } from "react";
import { useTranslation } from 'react-i18next';
import { Row, Col, Card, CardBody } from "reactstrap";

import { useNavigate, useLocation } from "react-router-dom";


const TabDetails = () => {

  const navigate = useNavigate();
  const { t, i18n } = useTranslation();

  const [customer, setCustomer] = useState("");
  const [warrantydet, setwarranty] = useState("");
  // const [user, setUser] = useState("");

  const location = useLocation();
  const { state } = location;
  console.log("Received Object:-", state);

  useEffect(() => {
    i18n.changeLanguage(localStorage.getItem('language'));
    setData();
  }, []);


  function setData() {
    if (state) {
      console.log("Going to set data object for display")
      //   setLog(state)
      //   getuser(state.user_id);
      setCustomer(state);
      setwarranty(state.warrantyData[0])
    }
    else {
      navigate(-1)
      window.location.reload()
    }
  }

  return (
    <Fragment>
      <Row>
        <Col md="6">
          <Card className="main-card mb-3">
            <CardBody>
              <table className="table table-striped table-bordered nowrap">
                <tbody>
                  <tr>
                    <th style={{ width: "30%" }}>{t('Salutation')}</th>
                    <td>{customer.salutation}</td>
                  </tr>
                  <tr>
                    <th style={{ width: "30%" }}>{t('First Name')}</th>
                    <td>{customer.first_name}</td>
                  </tr>
                  <tr>
                    <th style={{ width: "30%" }}>{t('Last Name')}</th>
                    <td>{customer.last_name}</td>
                  </tr>
                  {/* <tr>
                    <th style={{ width: "30%" }}>{t('Marital Status')}</th>
                    <td>{customer.mar_status}</td>
                  </tr> */}
                  {/* <tr>
                    <th style={{ width: "30%" }}>{t('Birth Date')}</th>
                    <td>{new Date(customer?.birth_date).toDateString().split(' ').slice(1).join(' ')}</td>
                  </tr> */}
                  <tr>
                    <th style={{ width: "30%" }}>{t('Phone Number')}</th>
                    <td>{customer.phone}</td>
                  </tr>
                  <tr>
                    <th style={{ width: "25%" }}>{t('Email Address')}</th>
                    <td>{customer.email}</td>
                  </tr>
                  {/* <tr>
                    <th style={{ width: "25%" }}>{t('Password')}</th>
                    <td>********</td>
                  </tr> */}
                  {/* <tr>
                    <th style={{ width: "30%" }}>{t('Registration Date')}</th>
                    <td>{new Date(warrantydet.reg_date).toDateString().split(' ').slice(1).join(' ')}</td>
                  </tr> */}
                  {/* <tr>
                    <th style={{ width: "30%" }}>{t('Current Status')}</th>
                    <td>{customer.status}</td>
                  </tr> */}
                </tbody>
              </table>
            </CardBody>
          </Card>
        </Col>
        <Col md="6">
          <Card className="main-card mb-3">
            <CardBody>
              <table className="table table-striped table-bordered nowrap">
                <tbody>
                  <tr>
                    <th style={{ width: "40%" }}>{t('House No./Lot No./Floor/Block No')}</th>
                    <td>{customer.house_no}</td>
                  </tr>
                  <tr>
                    <th style={{ width: "40%" }}>{t('Building Name/Street Address')}</th>
                    <td>{customer.build_street}</td>
                  </tr>
                  <tr>
                    <th style={{ width: "25%" }}>{t('City & State')}</th>
                    <td>{customer.city}, {customer.state}</td>
                  </tr>
                  <tr>
                    <th style={{ width: "30%" }}>{t('Zip Code')}</th>
                    <td>{customer.zip_code}</td>
                  </tr>
                  <tr>
                    <th style={{ width: "30%" }}>{t('Country')}</th>
                    <td>{customer.country}</td>
                  </tr>
                </tbody>
              </table>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Fragment>
  );

};
export default TabDetails;