import React, { useEffect, useState, Fragment } from "react";
import { useTranslation } from 'react-i18next';
import { useNavigate, useLocation, Link } from "react-router-dom";
import { Row, Col, Card, CardBody } from "reactstrap";

import LoadingOverlay from "react-loading-overlay-ts";
import DNALoader from "../../../components/Loaders/DnaLoader";
import "../../../assets/custom css/loadoverlay.scss";

import Alerts from "../../../components/Alerts/alerts";

// import GenFilter from "../../../components/Table Filters/GenFilter";
import FilterBySerial from "../../../components/Table Filters/FilterBySerial";
import DataTable from 'react-data-table-component';

import WarrantyDetails from '../../../components/Modals/WarrantyDetails';

import CustomersService from "../customers.service";
import AuthService from "../../Authentication/auth.service";



const TabWarranty = () => {

  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [warranty, setwarranty] = useState([]);
  const [customerId, setCustomerId] = useState("");


  const location = useLocation();
  const { state } = location;
  console.log("Received Object:-", state);
  

  useEffect(() => {
    i18n.changeLanguage(localStorage.getItem('language'));
    setCustomerId(state._id);
    fetchWarranties(state._id);
    // setData();
  }, []);

  // function setData() {
  //   if (state) {
  //     console.log("Going to set data object for display")
  //     //   setLog(state)
  //     //   getuser(state.user_id);
  //     setwarranty(state.warrantyData);
  //   }
  //   else {
  //     navigate(-1)
  //     window.location.reload()
  //   }
  // };

  function fetchWarranties(id) {
    setLoading(true);
    CustomersService.getSingleCustomer(id).then((response) => {
      setLoading(false);
      setwarranty(response.data.data[0].warrantyData);
      console.log("Fetched Warranties:-", warranty);
    },
    (error) => {
      setLoading(false);
      const errContent =
      (error.response && error.response.data) || error.message || error.toString();
      if(errContent) {
        Alerts.swalErrorAlert(errContent)
      }
      else {
        Alerts.swalErrorAlert(t("Server Error, Please try again later."))
      }
    })
  };


  function confirmDelete(serialno, cust_id) {
    let msg = t("Once deleted, you will not be able to recover this warranty!")
    Alerts.swalConfirmDeleteAlert(msg).then(res => {
      console.log("Resp:-", res)
      if (res === true) {
        sendDelete(serialno, cust_id)
      }
    })
  };

  function sendDelete(serial_no, custid) {
    console.log("Delete Warranty serial and customer ID:-", serial_no, custid)
    setLoading(true);
    CustomersService.deleteWarranty(serial_no, custid).then((response) => {
      console.log("Delete Warranty response:-", response)
      setLoading(false);
      // getCustomers(currentPage, pageLimit)
      if (response.data.status) {
        fetchWarranties(state._id);
        if (response.data.message !== '') {
          Alerts.swalSuccessAlert(response.data.message)
        }
        else {
          Alerts.swalSuccessAlert(t("Warranty Deleted Successfully."))
        }
        console.log(response.data.message)
      }
      else {
        if (response.data.message !== '') {
          if (response.data.message === "Unauthorized") {
            AuthService.logout();
            navigate("/login");
            window.location.reload();
          }
          else {
            Alerts.swalErrorAlert(response.data.message);
          }
        }
        else {
          Alerts.swalErrorAlert(t("Error: Can't delete the Warranty."))
        }
      }
    },
      (error) => {
        setLoading(false);
        const errContent =
          (error.response && error.response.data) || error.message || error.toString();
        Alerts.swalErrorAlert(t("Delete operation failed"))
      })
  };

  const TBcolumns = [
    {
      name: t('Name/Model'),
      selector: row => row.prod_model,
    },
    {
      name: t('Product Serial No'),
      selector: row => row.prod_serial_no,
    },
    {
      name: t('Date of Purchase'),
      selector: row => new Date(row.purchase_date).toDateString().split(' ').slice(1).join(' '),
    },
    {
      name: t('Actions'),
      selector: (row) => {
        return (
          <div>
            <WarrantyDetails data={row} />
            <a className="DeleteWarrantyData" onClick={() => confirmDelete(row.prod_serial_no, customerId)} style={{ cursor: "pointer" }}>
              <i className="pe-7s-trash icon-gradient bg-ripe-malin btn-lg mb-2 px-0"> </i>
            </a>
          </div>
        );
      }
    },
  ];

  const [filterText, setFilterText] = React.useState('');
  const [resetPaginationToggle, setResetPaginationToggle] = React.useState(false);
  const filteredItems = warranty.filter(
    item => item.prod_serial_no && item.prod_serial_no.toLowerCase().includes(filterText.toLowerCase()),
  );

  const subHeaderComponentMemo = React.useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText('');
      }
    };
    return (
      // <GenFilter onFilter={e => setFilterText(e.target.value)} onClear={handleClear} filterText={filterText} />
      <FilterBySerial onFilterSerial={e => setFilterText(e.target.value)} onClear={handleClear} filterTextSerial={filterText} />
    );
  }, [filterText, resetPaginationToggle]);


  return (
    <Fragment>
      <Row>
        <Col md="12">
          <Card className="main-card mb-3">
            <CardBody>
              <LoadingOverlay tag="div" active={loading}
                styles={{ overlay: (base) => ({ ...base }) }}
                spinner={<DNALoader />}>
                <DataTable
                  columns={TBcolumns}
                  data={filteredItems}
                  pagination
                  paginationResetDefaultPage={resetPaginationToggle} // optionally, a hook to reset pagination to page 1
                  subHeader
                  subHeaderComponent={subHeaderComponentMemo}
                  selectableRowDisabled={true}
                  persistTableHead
                />
              </LoadingOverlay>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Fragment>
  );

};
export default TabWarranty;