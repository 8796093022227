import React, { useState, useRef, useEffect } from "react";
import { useTranslation } from 'react-i18next';
import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import CheckButton from "react-validation/build/button";
import axios from 'axios';

import {Card, CardBody, CardTitle } from "reactstrap";
import LoadingOverlay from "react-loading-overlay-ts";
import DNALoader from "../../components/Loaders/DnaLoader";
import "../../assets/custom css/loadoverlay.scss";

import Alerts from "../../components/Alerts/alerts";

import DynModuleService from "./dynamicmodules.service";
import AuthService from "../Authentication/auth.service";
import { useNavigate, useParams,useLocation } from 'react-router-dom';



const UpdationForm = () => {

    const navigate = useNavigate();
    const { t, i18n } = useTranslation();
    const location = useLocation();
    const { state } = location;
    const params = useParams();
    var  pathname = location.pathname;
    pathname =  pathname.split('/')[2];
    var pathData = {}

    pathData.Parent_referal_name = "";
    pathData.redirect_param = "";
    pathData.Parentid = "";
    pathData.id = pathname;
    if(pathData.id){
      pathData.redirect_param = pathname;
      var text = pathname;
      if(text.indexOf("-") > -1){
        pathData.id = text.split('-')[0];
        pathData.Parentid = text.split('-')[1];
        pathData.Parent_referal_name = text.split('-')[2];
    
      }
    }
    
    const [loading, setLoading] = useState(false);
    const form = useRef();
    const [pre_params, setpre_params] = useState(pathData)
    const [collectionlist, setcollectionlist] = useState([])
    const [fieldlist, setfieldlist] = useState([])
    const [fieldlist_validate, setfieldlist_validate] = useState([])

    useEffect(() => {
      
      i18n.changeLanguage(localStorage.getItem('language'));
      fetchDynamicData();
    }, []); 
      
    const fetchDynamicData = async () => {
      try {
        const response = await DynModuleService.get_cruds_dynamic_data(pre_params.id);
        if(response.data.message === "Unauthorized") {
          AuthService.logout();
          navigate("/login");
          window.location.reload();
        }
        else {
          const data = await response.data.data;
          
          var custom_fields = data[0].custom_fields;
          for(var i=0;i < custom_fields.length; i++){
            if(custom_fields[i].field_parent !==''){
              
              const optresponse = await DynModuleService.getcollection(custom_fields[i].parent_col,{},{},1000); 
              const optdata = await optresponse.data.data;
              
              var options = [];
              
              for(var j=0;j < optdata.length; j++){

                var object1 = optdata[j];
                var object1key = Object.keys(object1)
                options.push({label:optdata[j][object1key[1]],value:optdata[j]._id})
              }
              custom_fields[i].options = options;
            }
            if(custom_fields[i].parent_col === pre_params.Parent_referal_name ){
              custom_fields[i].default_value = pre_params.Parentid
            }else{
              custom_fields[i].default_value = ""
            }
          }
          
          data[0].custom_fields = custom_fields;
          setcollectionlist(data[0]);
          setfieldlist(data[0].custom_fields);
          var cust = data[0].custom_fields;
          var validations = {};
          for (var i =0; i < cust.length;i++) {
            validations[cust[i].map] = cust[i].is_required;
          }
          setfieldlist_validate(validations);
        }
      } catch (error) {
          console.log("error", error);
      }
    };
  
  
    const handleSubmit = (e) => {
      e.preventDefault();
      let data = new FormData(e.target);
      let formdata = {};
      var i =0;
      var field_errors = 0;
      for (let [key, value] of data) {
        if (formdata[key] !== undefined) {
          if (!Array.isArray(formdata[key])) {
            formdata[key] = [formdata[key]];
          }
          formdata[key].push(value);
        } 
        else {
          
          if(fieldlist_validate[key] === "1" && value===""){
            field_errors = 1;
            document.getElementById(key).style.border = "1px solid red";
          }
          formdata[key] = value;
        }
        i=i+1;
      }

      if(field_errors ===0){
        formdata['_id']=state._id;
        var payloadData = {
            functionName: "InsertdynCollectionData",
            postData: {
              collection_name:collectionlist.referal_name,
              formdata:formdata
            }
        }
        setLoading(true);
        
        DynModuleService.insertCollectionData(payloadData).then((response) => {        
         
          setLoading(false);
          if(response.data.status){
            navigate("/viewdata/"+pre_params.redirect_param)
            if(response.data.message !== '') {
              Alerts.swalSuccessAlert(response.data.message)
            }
            else {
              Alerts.swalSuccessAlert(t("Data Updated successfully."));
            }
          }
          else {
            if(response.data.message !== '') {
              if(response.data.message === "Unauthorized") {
                AuthService.logout();
                navigate("/login");
                window.location.reload();
              }
              else {
                Alerts.swalErrorAlert(response.data.message);
              }
            }
            else {
              Alerts.swalErrorAlert(t("Error: Can't Update data at the moment. Please try again later."))
            }
          }
        },
        (error) => {
          setLoading(false);
          const resMessage = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
          Alerts.swalErrorAlert(t("Network Operation failed."))
        });
        
      }
    
    };
    

    return (
      <>
        <div className="app-main__outer">
          <div className="app-main__inner">
            <div className="app-page-title">
              <div className="page-title-wrapper justify-content-center">
                <div className="page-title-heading fw-bold">
                  <div className="page-title-icon">
                    <i className="pe-7s-plus icon-gradient bg-happy-green" />
                  </div>
                  
                      <div>{collectionlist.page_title}
                        <div className="page-title-subheading">
                        {t('Fill the form below to update')}  {collectionlist.page_title}.
                        </div>
                      </div>
                   
                </div>
              </div>
            </div>
        
            <div className="d-flex justify-content-center">
              <div className="col-md-6">
                <Card className="main-card mb-3">
                  <CardBody>
                    <LoadingOverlay tag="div" active={loading}
                      styles = {{overlay: (base) => ({...base})}}
                      spinner = {<DNALoader/>}>  
                      <Form onSubmit={handleSubmit} ref={form}>
                        {fieldlist && fieldlist.map((field, index) => {
                          if(field.field_type === 'text' || field.field_type === 'email' || field.field_type === 'number'|| field.field_type === 'password'){
                            return (
                              <>
                                <div className="row" key={index}>
                                  <div className="col-md-12 col-xl-12" >
                                    <div className="form-group" >
                                      <label htmlFor="name">{field.field_lable}</label>
                                      <Input type={field.field_type} className="form-control" placeholder={field.field_lable}  name={field.map} id={field.map} value={state[field.map]}   />
                                    </div>
                                  </div>
                                </div>
                                <br/>
                              </>
                            )
                          }
                          else if(field.field_type === 'dropdown'){
                            
                            return (
                              <>
                                <div className="row" key={index}>
                                  <div className="col-md-12 col-xl-12" >
                                    <div className="form-group" >
                                      <label htmlFor="name">{field.field_lable}</label>
                                      <select className="form-select" name={field.map} id={field.map}  value={field.default_value}   >
                                          <option disabled={false} value="">{t('--Choose an option--')}</option>
                                          {field.options.map((option, optionsindex) =>  (
                                              <option key={field.map+optionsindex} value ={option.value} >{option.label}</option>
                                          )  )}
                                      </select>
                                    </div>
                                  </div>
                                </div>
                              <br/>
                              </>
                            )
                          }
                          else if(field.field_type === 'radio' || field.field_type === 'checkbox'){
                            return (
                              <>
                                <div className="row" key={index}>
                                  <div className="col-md-12 col-xl-12" ><label htmlFor="name">{field.field_lable}</label></div>
                                  <br/>
                                  <div className="col-md-12 col-xl-12" >
                                    <ul className="child mb-0 p-0">
                                      {field.options.map((option, optionsindex) =>   (
                                        <li className="form-check form-check-inline">
                                          <input className="form-check-input" 
                                              type={field.field_type}
                                              value ={option.value}  name={field.map} id={field.map+optionsindex}
                                          />
                                          <label className="form-check-label" htmlFor="users">
                                            {option.label}
                                          </label>
                                        </li>
                                      ))}   
                                    </ul>
                                  </div>
                                </div>
                                <br/>
                              </>
                            )
                          }
                          else {
                            return (
                              <>
                                <div className="row" key={index}>
                                  <div className="col-md-12 col-xl-12" >
                                    <div className="form-group" >
                                      <label htmlFor="name">{field.field_lable}</label>
                                      <Input type="text" className="form-control" placeholder={field.field_lable}  name={field.map} id={field.map}    />
                                    </div>
                                  </div>
                                </div>
                              <br/>
                              </>
                            )
                          }
                        })}
                        <br/>
                        <div className="row">
                          <div className="col-md-12 col-xl-12" >
                            <center>
                              <button type="button" onClick={() => navigate(-1)} className="btn btn-outline-light btn-hover-shine me-3 mt-3">{t('Cancel')}</button>
                              <button className="btn btn-custom-color btn-hover-shine me-3 mt-3" >{t('Update data')}</button>
                            </center>
                          </div>
                        </div>
                      </Form>
                    </LoadingOverlay>
                  </CardBody>
                </Card>
              </div>
            </div>
          </div>
        </div>
      </>
    );


}

export default UpdationForm;